export const palette = {
  light: '#FFFFFF',
  dark: '#111111',
  red: '#FF6F61', // Replaced pink with the dominant gradient start color
  deepRed: '#DE1D23', // Replaced pink gradient end with the dominant gradient end color
  dustyRed: '#A15854', // Adjusted dusty pink to match the red tones
  powderRed: '#FFF5F4', // Adjusted powder pink to a lighter red tone
  darkGrey: '#333333',
  grey: '#727579',
  lightGrey: '#AEB0B2',
  backgroundGrey: '#F4F3F3',
  backgroundLightGrey: '#C4F3F320',
  transparent: '#00000000',
};
