import {FC, useEffect, useState} from 'react';

import PageHeader from 'components/PageHeader';

import {
    BlockPrice,
    DashboardContainer,
    FirstBlockContainer,
    Flex,
    HeadText,
    HeadTextP,
    PriceP
} from 'pages/Main/Main.styles';

import styles from './styles.module.css'
import Sign from "components/Sign";
import Button from "components/Button";
// import UncontrolledExample from "pages/Main/components/Carousel";
import Slider from "pages/Main/components/Carousel";
import HomePage from "./components/Homepage";
import FundingBotSection from "./components/FuturesInfo";
import PricingSection from "./components/PriceInfo";
import FAQ from "./components/FaqInfo";
import {useLocation} from "react-router-dom";
import useCustomAuthHook from "utils/authHook";

const Main: FC<any> = ({isOpenSign, setIsOpenSign}) => {
  // const isMobileView = useSelector(selectIsMobileView);
    const closeSignModal = () => {
        setIsOpenSign(false);
    };

    useCustomAuthHook()

    useEffect(() => {
        closeSignModal()
    }, [])


    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.replace('#', ''));
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth' });
                }, 0);
            }
        }
    }, [location]);
  return (
    <DashboardContainer>
        {isOpenSign && <Sign closeModal={closeSignModal}/>}
        <HomePage/>
        <div id="features" className={styles.carousel}>
            <Slider/>
        </div>
        <FundingBotSection/>
        <PricingSection/>
        <FAQ/>
    </DashboardContainer>
  );
};

export default Main;
