import { FC, memo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useMatch } from 'react-router-dom';

import { APP_CONTAINER_ID } from 'constants/common';

import { selectIsTabletAndHigher } from 'store/selectors/ui';

import Header from 'components/Header';
import Navbar from 'components/Navbar';

import { AppContainer, AppContent, Arrow, BackLink } from './ApplicationLayout.styles';
import Footer from "components/Footer";

const ApplicationLayout = ({children, setIsOpenSign}: {children: React.ReactNode, setIsOpenSign: any}) => {
  const [isOpenSidebar, setIsOpenSideBar] = useState(false);
  const openSideBar = useCallback(() => setIsOpenSideBar(true), []);
  const closeSideBar = useCallback(() => setIsOpenSideBar(false), []);

  const isDesktopOrTablet = useSelector(selectIsTabletAndHigher);
  const isMobileLandscapeAndLower = !isDesktopOrTablet;

  const isApplicationDetails = !!useMatch('pipeline/:id');

  return (
    <>
      <Header openSideBar={openSideBar} setIsOpenSign={setIsOpenSign} />
      {isMobileLandscapeAndLower && (
        <Navbar closeSideBar={closeSideBar} isOpenSidebar={isOpenSidebar} />
      )}
      <AppContainer id={APP_CONTAINER_ID}>
        <AppContent data-testid="app-content">
          {/*TODO: will be reworked for multiple usage*/}
          {isApplicationDetails && (
            <BackLink to="../pipeline">
              <Arrow /> to Pipeline
            </BackLink>
          )}
          {/*{isDesktopOrTablet && (*/}
          {/*  <Navbar closeSideBar={closeSideBar} isOpenSidebar={isOpenSidebar} />*/}
          {/*)}*/}
          <Outlet />
            <>{children}</>
        </AppContent>
      </AppContainer>
        <Footer/>
    </>
  );
};

export default memo(ApplicationLayout);
