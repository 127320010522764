import React, {useState, ChangeEvent, useEffect} from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { Container, Row, Col, Button as BootstrapButton, Accordion, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Typography, TextField, MenuItem, Select, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import Chart from './components/Charts';
import {RouteLocal} from "constants/common";
import {useSelector} from "react-redux";
import {selectAuthData} from "store/selectors/auth";
import { darkTheme } from '../../styles/themes/dark';
import {toast} from "react-toastify";
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HeaderSection = styled.div`
  background-color: ${({ theme }) => theme.colors.paginationBackground};
  padding: 20px;
  border-bottom: 1px solid #e0e0e0;
  animation: ${fadeIn} 1s ease-in-out;
  text-align: center;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled(Typography)`
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  line-height: 1; /* Added for better text wrapping */

  @media (max-width: 768px) {
    font-size: 1rem; /* Reduced font size for better fitting */
    word-wrap: break-word; /* Prevent text overflow */
    hyphens: auto; /* Allow hyphenation for better word splitting */
  }
`;

const Subtitle = styled(Typography)`
  font-size: 1.5rem;
  margin-top: 10px;
  line-height: 1.2; /* Adjusted line height */

  @media (max-width: 768px) {
    font-size: 1.2rem; /* Reduced font size slightly */
  }
`;

const Section = styled.div`
  background-color: ${({ theme }) => theme.colors.paginationBackground};
  padding: 20px;
  
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  animation: ${fadeIn} 2s ease-in-out;

  @media (max-width: 768px) {
    padding: 15px;
    margin-bottom: 15px;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  
  overflow-x: auto; /* Позволяет горизонтальную прокрутку */

  /* Убираем скролл-бары в Webkit-браузерах */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  min-width: 600px; /* Минимальная ширина таблицы */

  thead {
    display: table-header-group; /* Поддержка фиксированных заголовков при прокрутке */
  }

  tbody {
    display: table-row-group;
  }

  th, td {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: left; /* Выравнивание текста по левому краю */
  }

  th {
    font-weight: bold;
  }

  tr:nth-child(even) {
   
  }

  @media (max-width: 768px) {
    th, td {
      padding: 10px 8px;
      font-size: 0.9rem; /* Уменьшаем размер шрифта для меньших экранов */
    }
  }
`;


const Button = styled(BootstrapButton)`
  background: linear-gradient(45deg, #ff6f61, #de1d23);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  margin-top: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #de1d23, #ff6f61);
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 8px 16px;
    margin: 8px 0;
    width: 100%; /* Ensures buttons are the same width */
    max-width: 300px; /* Optional: Set a max-width to control button size */
    align-self: center; /* Centers buttons in the flex container */
  }
`;

const NavButton = styled(Button)`
  margin: 10px 20px;

  @media (max-width: 768px) {
    margin: 8px 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center; /* Center align all buttons */
    margin-bottom: 15px;
  }
`;

export interface Trade {
    type: string;
    date: string;
    volume: string;
    exchanges: string;
    profitLoss: string;
}

const ChoosePlanButton = styled(BootstrapButton)`
  background: linear-gradient(45deg, #ff6f61, #de1d23);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  padding: 15px 30px;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #de1d23, #ff6f61);
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 10px 20px;
  }
`;
const Dashboard: React.FC = () => {
    const [trades, setTrades] = useState<Trade[]>([]);
    const [newTrade, setNewTrade] = useState<Trade>({ type: '', date: '', volume: '', exchanges: '', profitLoss: '' });
    const [activeSection, setActiveSection] = useState('dashboard');
    const { userId, user: {email: email }, token } = useSelector(selectAuthData);
    const [payments, setPayments] = useState([]);
    async function fetchPayments(userId: any) {
        try {
            const response = await fetch(`${RouteLocal}/payments/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}` // Assuming token is stored in localStorage
                }
            });

            if (!response.ok) {
                toast(`Error: ${response.status} ${response.statusText}`, { autoClose: 10_000});
            }

            const payments = await response.json();
            console.log("payments",payments)
            return payments;
        } catch (error) {
            console.error('Error fetching payments:', error);
            return [];
        }
    }

    useEffect(() => {
        const getPayments = async () => {
            const payments = await fetchPayments(userId);
            setPayments(payments);
            // Можете здесь сохранить платежи в состоянии компонента или выполнить другие действия с ними
        };

        if (userId) {
            getPayments();
        }
    }, [userId]);


    const handleInputChange = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
    ) => {
        const { name, value } = e.target;
        setNewTrade((prev) => ({ ...prev, [name]: value }));
    };

    const addTrade = () => {
        setTrades((prev) => [...prev, newTrade]);
        setNewTrade({ type: '', date: '', volume: '', exchanges: '', profitLoss: '' });
    };

    const renderContent = () => {
        switch (activeSection) {
            case 'dashboard':
                return (
                    <>
                        <Row>
                            <Col md={12}>
                                <Section>
                                    <Subtitle variant="h5">Recent Orders</Subtitle>
                                    <TableContainer>
                                        <Table>
                                            <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Address</th>
                                                <th>Network</th>
                                                <th>User ID</th>
                                                <th>Amount</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {payments.length && payments.map((payment: any) => (
                                                <tr key={payment._id}>
                                                    <td>{new Date(payment.created).toLocaleDateString()}</td>
                                                    <td>{payment.addressSender}</td>
                                                    <td>{payment.networkSender}</td>
                                                    <td>{payment.userId}</td>
                                                    <td>{payment.sum}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                    </TableContainer>
                                </Section>
                            </Col>
                        </Row>
                    </>
                );
            case 'trading':
                return (
                    <>
                        <Row>
                            <Col>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>
                                            Trade Data
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Section>
                                                <Subtitle variant="h5">Enter Trade Data</Subtitle>
                                                <FormControl fullWidth margin="normal">
                                                    <InputLabel id="type-label">Type</InputLabel>
                                                    <Select
                                                        labelId="type-label"
                                                        name="type"
                                                        value={newTrade.type}
                                                        onChange={handleInputChange}
                                                    >
                                                        <MenuItem value="Arbitrage">Arbitrage</MenuItem>
                                                        <MenuItem value="Funding">Funding</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                <TextField
                                                    label="Date"
                                                    type="date"
                                                    name="date"
                                                    value={newTrade.date}
                                                    onChange={handleInputChange}
                                                    fullWidth
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                <TextField
                                                    label="Volume"
                                                    name="volume"
                                                    value={newTrade.volume}
                                                    onChange={handleInputChange}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    label="Exchanges"
                                                    name="exchanges"
                                                    value={newTrade.exchanges}
                                                    onChange={handleInputChange}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <TextField
                                                    label="Profit/Loss"
                                                    name="profitLoss"
                                                    value={newTrade.profitLoss}
                                                    onChange={handleInputChange}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                                <BootstrapButton onClick={addTrade} variant="primary" style={{ marginTop: '10px' }}>Add Trade</BootstrapButton>
                                            </Section>
                                            <Section>
                                                <Subtitle variant="h5">Trade History</Subtitle>
                                                <Table>
                                                    <thead>
                                                    <tr>
                                                        <th>Type</th>
                                                        <th>Date</th>
                                                        <th>Volume</th>
                                                        <th>Exchanges</th>
                                                        <th>Profit/Loss</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {trades.map((trade, index) => (
                                                        <tr key={index}>
                                                            <td>{trade.type}</td>
                                                            <td>{trade.date}</td>
                                                            <td>{trade.volume}</td>
                                                            <td>{trade.exchanges}</td>
                                                            <td>{trade.profitLoss}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </Table>
                                            </Section>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Container>
            <HeaderSection>
                <Title variant="h1">Dashboard</Title>
                <Subtitle variant="h4">Arbitrage and Funding Trading Overview</Subtitle>
            </HeaderSection>
            <ButtonContainer>
                <NavButton variant="primary" onClick={() => setActiveSection('dashboard')}>Dashboard</NavButton>
                <Link to="/#pricing-section">
                    <ChoosePlanButton>Choose Your Plan</ChoosePlanButton>
                </Link>
                <NavButton variant="primary" onClick={() => setActiveSection('trading')}>Trading</NavButton>
            </ButtonContainer>
            {renderContent()}
        </Container>
    );
};

export default Dashboard;
