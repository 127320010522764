import { FC, useCallback, useState, useEffect } from "react";
import './sign.css';
import { useDispatch } from "react-redux";
import {getDomainName} from "utils/api";
import {loginSuccess} from "store/slices/auth";
import {toast} from "react-toastify";
import {saveToken} from "utils/auth";
import {Link, useNavigate } from "react-router-dom";
import styled, { keyframes } from 'styled-components';
import { Typography } from '@mui/material';
import { Container } from 'react-bootstrap';
import { RouteLocal } from "constants/common";
import AuthContainer from "components/AuthContainer";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative; /* Updated */
  background-color: ${({ theme }) => theme.colors.card.background};
  box-shadow: 0px 4px 8px ${({ theme }) => theme.colors.shadow};
  padding: 50px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  animation: ${fadeIn} 0.5s ease-in-out;
`;
const CloseButton = styled.button` /* New close button */
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const Title = styled(Typography)`
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  background-color: ${({ theme }) => theme.colors.card.background};
  border-radius: 5px;
  border: 1px solid #ccc;
  transition: border 0.3s;

  &:focus {
    border-color: #1161ee;
    outline: none;
  }
`;

const Button = styled.button`
  background: linear-gradient(45deg, #ff6f61, #de1d23);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  padding: 15px 30px;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #de1d23, #ff6f61);
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
`;

const Tab = styled.label`
  font-size: 1rem;
  margin-right: 15px;
  padding-bottom: 5px;
  margin: 0 15px 10px 0;
  display: inline-block;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: color 0.3s, border-color 0.3s;

  &.active {
    color: #1161ee;
    border-color: #1161ee;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Hr = styled.div`
  height: 2px;
  margin: 30px 0;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const FootLink = styled.div`
  text-align: center;
  margin-top: 10px;
`;

const Sign:FC<{ closeModal: () => void }> = ({ closeModal })=> {
    const navigate = useNavigate();
    const [signUpData, setSignUpData] = useState({ password: '', email: '', userName: '' });
    const [signInData, setSignInData] = useState({ password: '', email: '' });
    const [keepSignedIn, setKeepSignedIn] = useState(false); // State for "Keep me Signed in" checkbox
    const [signUpErrors, setSignUpErrors] = useState({ password: '', email: '' });
    const [signInErrors, setSignInErrors] = useState({ password: '', email: '' });
    const [activeTab, setActiveTab] = useState<'signIn' | 'signUp'>('signIn');

    const handleTabChange = useCallback( (e: React.ChangeEvent<HTMLInputElement>) => {
        setActiveTab(e.target.name === 'auth-tab-sign-in' ? 'signIn' : 'signUp');
    },[activeTab]);

    const handleModalClick = useCallback((e: React.MouseEvent) => {
        if ((e.target as HTMLElement).id === 'modal-overlay') {
            closeModal();
        }
    }, [closeModal]);
    const dispatch = useDispatch();

    const handleInputChange = useCallback((type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        switch (type) {
            case 'signIn':
                setSignInData(prevState => ({ ...prevState, [name]: value }));
                break;
            case 'signUp':
                setSignUpData(prevState => ({ ...prevState, [name]: value }));
                break;
            default:
                break;
        }
    }, [setSignInData, setSignUpData]);

    const handleSignInSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(signInData);
        // Handle sign in
        try {
            fetch(`${RouteLocal}/login`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ password: signInData.password, email: signInData.email }),
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        toast('Login failed: Invalid credentials or server error.', { autoClose: 10_000});
                    }
                })
                .then((data) => {
                    const { token, userResp: user } = data;
                    saveToken(token);
                    dispatch(loginSuccess({ user, token }));
                    navigate('/dashboard');

                })
                .catch((error) => {
                    // Handle errors here
                    toast('Network error or invalid server response.', { autoClose: 10_000});
                });
        } catch (error) {
            // This block won't catch fetch errors
            toast('Unexpected error occurred. Please try again later.', { autoClose: 10_000});
        }
    }, [signInData]);

    const handleSignUpSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const errors = { ...signUpErrors };

        console.log(errors)
        if (signUpData.email.length === 0 || !isValidEmail(signUpData.email)) {
            errors.email = 'Invalid email address';
        } else {
            errors.email = '';
        }
        console.log(errors)

        console.log("signUpData.password.length",signUpData.password.length);
        if (signUpData.password.length < 6) {
            errors.password = 'Password must be at least 6 characters long';
        } else {
            errors.password = '';
        }
        console.log(errors)

        if (errors.email || errors.password) {
            setSignUpErrors(errors);
            console.log(errors)
        } else {
            setSignUpErrors({ email: '', password: '' });
            // Proceed with sign up
            console.log(signUpData);
            try {
                //const response = await register(email, password); // Запрос на регистрацию
                console.log({password: signUpData.password, email :signUpData.email});
                fetch(`${RouteLocal}/register`, {
                    method: "POST",
                    //
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({password: signUpData.password, email :signUpData.email}),
                })
                    .then((response) => {
                        if (response.status === 201) {
                            return response.json();
                        } else {

                        }}
                    )
                    .then((data) => {
                        console.log(data);
                        toast(data?.message, { autoClose: 10_000});
                        setActiveTab('signIn');
                    });
                // Обработка успешной регистрации
            } catch (error:any) {
                // Обработка ошибок регистрации
                toast(error, { autoClose: 10_000});
            }
        }
    }, [signUpData, signUpErrors]);

    const handleKeepSignedInChange = useCallback(() => {
        setKeepSignedIn(prevState => !prevState);
    }, [setKeepSignedIn]);

    const isValidEmail = (email: string) => {
        // Email validation logic (you can use a library like validator.js for this)
        // For simplicity, I'm using a basic regex here
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <>
            <AuthContainer>
        <ModalOverlay id="modal-overlay" onClick={handleModalClick}>
            <ModalContent>
                <CloseButton onClick={closeModal}>×</CloseButton>
                <div>
                    <input
                        id="signIn"
                        type="radio"
                        name="auth-tab"
                        checked={activeTab === 'signIn'}
                        onChange={handleTabChange}
                        style={{ display: 'none' }}
                    />

                    <Tab
                        className={activeTab === 'signIn' ? 'active' : ''}
                        onClick={() => handleTabChange({ target: { name: 'auth-tab-sign-in' } } as React.ChangeEvent<HTMLInputElement>)}
                    >
                        Sign In
                    </Tab>
                    <input
                        id="signUp"
                        type="radio"
                        name="auth-tab"
                        checked={activeTab === 'signUp'}
                        onChange={handleTabChange}
                        style={{ display: 'none' }}
                    />
                    <Tab
                        className={activeTab === 'signUp' ? 'active' : ''}
                        onClick={() => handleTabChange({ target: { name: 'auth-tab-sign-up' } } as React.ChangeEvent<HTMLInputElement>)}
                    >
                        Sign Up
                    </Tab>
                    <div>
                        {activeTab === 'signIn' ? (
                            <Form onSubmit={handleSignInSubmit}>
                                <Title>Sign In</Title>
                                <div>
                                    <label htmlFor="user" className="label">Email</label>
                                    <Input id="user" name="email" type="email" onChange={handleInputChange('signIn')} />
                                </div>
                                <div>
                                    <label htmlFor="pass" className="label">Password</label>
                                    <Input id="pass" name="password" type="password" onChange={handleInputChange('signIn')} />
                                </div>
                                <div>
                                    <Button type="submit">Sign In</Button>
                                </div>
                                <Hr />
                                <FootLink>
                                    <Link to='/request_password_reset'>Forgot Password?</Link>
                                </FootLink>
                            </Form>
                        ) : (
                            <Form onSubmit={handleSignUpSubmit}>
                                <Title>Sign Up</Title>
                                <div>
                                    <label htmlFor="email" className="label">Email Address</label>
                                    <Input id="email" name="email" type="email" onChange={handleInputChange('signUp')} />
                                </div>
                                <div>
                                    <label htmlFor="pass" className="label">Password</label>
                                    <Input id="pass" name="password" type="password" onChange={handleInputChange('signUp')}/>
                                </div>
                                <div>
                                    <Button type="submit">Sign Up</Button>
                                </div>
                                <Hr />
                                <FootLink>
                                    <label
                                        htmlFor="signIn"
                                        style={{ cursor: 'pointer', color: '#1161ee' }}
                                        onClick={() => setActiveTab('signIn')}
                                    >
                                        Already have an account? Sign in here.
                                    </label>
                                </FootLink>
                            </Form>
                        )}
                    </div>
                </div>
            </ModalContent>
        </ModalOverlay>
            </AuthContainer>
        </>
    );
};

export default Sign;
