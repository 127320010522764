import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { store } from 'store';

import InitializeApp from 'components/InitializeApp';
import ThemeProvider from 'components/ThemeProvider';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './styles/fonts.css';

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <ThemeProvider >
        <Provider store={store}>
          <NiceModal.Provider>
            <InitializeApp>
              <App />
            </InitializeApp>
          </NiceModal.Provider>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
