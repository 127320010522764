import { Link } from 'react-router-dom';
import { darken } from 'polished';
import styled from 'styled-components';

import { HEADER_HEIGHT } from 'constants/ui';
import { MediaQueries } from 'enums/ui';

import { ReactComponent as ArrowWithLine } from 'assets/icons/arrowWithLine.svg';

export const AppContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  
  // related to bug 19439, helps to avoid hiding header when focusout from any field on IOS
  height: calc((var(--vh, 1vh) * 100) - ${HEADER_HEIGHT} -300);

  overflow-y: hidden;
  // when changing, also change in SelectField (connected#1)
  padding: 70px 0 0 0;

  @media (max-width: ${MediaQueries.Tablet}) {
    padding: 50px 0 0 0;
  }
`;

export const AppContent = styled.div`
  display: flex;
  width: 100%;

  height: min-content;
  padding: 0 5%;

  @media (max-width: ${MediaQueries.Tablet}) {
    justify-content: center;
  }
`;

export const BackLink = styled(Link)`
  position: absolute;
  top: 20px;
  justify-self: flex-start;

  color: ${({ theme }) => theme.colors.primary};

  :hover {
    color: ${({ theme }) => darken(theme.coefficients.primaryHovered, theme.colors.primary)};

    & > svg > path {
      fill: ${({ theme }) => darken(theme.coefficients.primaryHovered, theme.colors.primary)};
    }
  }

  @media (max-width: ${MediaQueries.Tablet}) {
    left: 5%;
  }
`;

export const Arrow = styled(ArrowWithLine)`
  margin-right: 5px;
  transform: rotate(180deg);
`;
