import { FC } from 'react';
import styled from 'styled-components';

import { MediaQueries } from 'enums/ui';



const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AuthContainer: FC = ({ children }) => {
  return <Container>{children}</Container>;
};

export default AuthContainer;
