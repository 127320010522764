import React, { FC, memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import { useDarkMode } from 'hooks/useDarkMode';
import { useClickOutside } from 'hooks/useClickOutside';
import { logout } from 'store/slices/auth';
import { selectAuthData } from 'store/selectors/auth';

import Skeleton from 'components/Skeleton';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { ReactComponent as MenuIcon } from 'assets/icons/burger-menu-svgrepo-com.svg';
import emptyAvatar from 'assets/images/emptyAvatar.png';

import styled, { css, keyframes } from 'styled-components';
import {Link, useNavigate} from 'react-router-dom';
import { Typography } from '@mui/material';
import LogoIcon from 'components/@icons/LogoSVG';
import ToggleButton from "components/ThemeProvider/ToggleButton";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const dropDownAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HeaderWrapper = styled.header<{ isLightMode: boolean }>`
  margin-top: -20px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  padding: 0 40px;
  box-shadow: 0px 10px 30px 0 ${({ theme }) => theme.colors.shadow};
  animation: ${fadeIn} 2s ease-in-out;

  @media (max-width: 768px) {
    padding: 0 20px;
    justify-content: space-between;
  }
`;


const HamburgerMenu = styled.div<{ isOpen: boolean }>`
  display: none;
  position: fixed; /* Ensures it stays in place while scrolling */
  top: 25px;
  right: 25px;
  z-index: 1001; /* Higher than HeaderWrapper's z-index to keep it on top */

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      transition: transform 0.3s;
      transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(0)')};
    }
  }
`;



const NavItems = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.bodyBackground};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    padding: 20px;
    box-shadow: 0px 10px 30px 0 ${({ theme }) => theme.colors.shadow};
    transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-100%)')};
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    overflow-y: auto;
  }
`;




const ScannerNavItem = styled.div<{ $isDropDownOpen: boolean }>`
  margin: 0 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  svg {
    transition: transform 0.7s;
    transform: ${({ $isDropDownOpen }) => ($isDropDownOpen ? 'rotate(180deg)' : 'rotate(0)')};
    margin-left: 5px;
  }
`;

const CenterBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  @media (max-width: 768px) {
    display: none;
  }
`;

const RightBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  margin-right: 15px;
  @media (max-width: 768px) {
    margin-top: 24px;
   margin-right: 44px;
  }
  @media (max-width: 350px) {
    display: none;
  }
`;

const NavItem = styled.div`
  margin: 0 15px;
  cursor: pointer;

  @media (max-width: 768px) {
    margin: 10px 0;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const ProfileImage = styled.img`
  border-radius: 50px;
  height: 30px;
  width: 30px;
  margin-right: 15px;
`;

const ProfileImageSkeleton = styled(Skeleton)`
  border-radius: 50px;
  height: 30px;
  width: 30px;
  margin-right: 15px;
`;

const FullName = styled(Typography)`
  margin-right: 10px;

  @media (max-width: 576px) {
    display: none;
  }
`;

const Arrow = styled(ArrowIcon)<{ $isRotated: boolean }>`
  margin-left: 5px;
  transition: 0.7s;
  ${props => props.$isRotated && css`
    transform: rotate(180deg);
  `}
`;

const RouterLink = styled(Link)`
  display: flex;
`;

const Logo = styled(LogoIcon)`
  height: 60px;
  width: 60px;
  object-fit: contain;
  transition: transform 0.3s ease, filter 0.3s ease;

  &:hover {
    transform: scale(1.1);
    filter: brightness(1.1);
  }
`;

const NameSkeleton = styled(Skeleton)`
  width: 90px;
  height: 18px;
  border-radius: 7px;
  margin-right: 5px;

  @media (max-width: 576px) {
    display: none;
  }
`;

const DropDownContainer = styled.div`
  position: absolute;
  right: 0;
  top: 55px;
  z-index: 10000;
  border-radius: 20px;
  height: min-content;
  width: 140px;
  padding: 5px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.card.background};
  box-shadow: 0px 10px 30px 0 ${({ theme }) => theme.colors.shadow};
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const StyledDropDownItem = styled(Typography)`
  padding: 10px;
`;

const DropDownItem = ({ children, ...props }: any) => (
    <StyledDropDownItem {...props} variant="body2">
        {children}
    </StyledDropDownItem>
);

const ScannerDropDown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10000;
  background-color: ${({ theme }) => theme.colors.card.background};
  box-shadow: 0px 10px 30px 0 ${({ theme }) => theme.colors.shadow};
  border-radius: 8px;
  overflow: hidden;
  animation: ${dropDownAnimation} 0.5s ease-in-out;
`;

const ScannerDropDownItem = styled.div`
  padding: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.bodyBackground};
  }
`;

const SignInUpButton = styled.button`
  background: linear-gradient(45deg, #ff6f61, #de1d23);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  margin-top: 10px;
  margin-right: 20px; /* Добавлено */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #de1d23, #ff6f61);
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 350px) {
    margin: 20px auto 10px auto; /* Устанавливаем отступ сверху и снизу */
    display: block;
  }
`;


const StyledMenuIcon = styled(MenuIcon)`
  font-size: 1.5rem;
  cursor: pointer;
  width: 20px;
  height: 20px;

  @media (max-width: 850px) {
    display: flex;
    align-items: center;
  }
`;

const Header: FC<{ openSideBar(): void, setIsOpenSign: (open: boolean) => void }> = ({ openSideBar, setIsOpenSign }) => {
    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [isScannerDropDownOpen, setIsScannerDropDownOpen] = useState(false);
    const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
    const dispatch = useDispatch();
    const isDarkMode = useDarkMode();
    const { isAuthenticated } = useSelector(selectAuthData);
    const [isProfileLoading, setIsProfileLoading] = useState(true);
    const isMobile = window.innerWidth < 350;
    const { colors: { text: { primary: textPrimary } } } = useTheme();
    const navigate = useNavigate();
    const onClickRightBlock = useCallback(() => setIsDropDownOpen(prev => !prev), []);
    const openSign = () => {
        setIsOpenSign(true)
    }

    const onLogOut = useCallback(() => {
        dispatch(logout());
        toast.dismiss();
        localStorage.clear();
        navigate('/')
    }, [dispatch]);

    const closeDropDown = useCallback(() => setIsDropDownOpen(false), []);

    const ref = useClickOutside<HTMLDivElement>(closeDropDown);
    const handleHamburgerClick = useCallback(() => {
        setIsHamburgerOpen(prev => {
            const newState = !prev;
            return newState;
        });
    }, []);
    const closeHamburgerMenu = () => {
        setIsHamburgerOpen(false);
    };
    return (
        <HeaderWrapper data-testid="header" isLightMode={!isDarkMode}>
            <RouterLink to="/">
                <Logo color={textPrimary} />
            </RouterLink>

            <HamburgerMenu isOpen={isHamburgerOpen} onClick={handleHamburgerClick}>
                <StyledMenuIcon />
            </HamburgerMenu>

            <NavItems isOpen={isHamburgerOpen}>
                <NavItem
                    onClick={() => {
                        const element = document.getElementById('features');
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth' });
                        }
                        closeHamburgerMenu();
                    }}
                >
                    Features
                </NavItem>

                <NavItem>Video</NavItem>
                <ScannerNavItem
                    onMouseEnter={() => setIsScannerDropDownOpen(true)}
                    onMouseLeave={() => setIsScannerDropDownOpen(false)}
                    $isDropDownOpen={isScannerDropDownOpen}
                    onClick={closeHamburgerMenu}
                >
                    Scanner
                    <Arrow $isRotated={isScannerDropDownOpen} />
                    {isScannerDropDownOpen && isAuthenticated && (
                        <ScannerDropDown>
                            <StyledLink to="/arbitrage_coins"><ScannerDropDownItem>InterExchange Arbitrage</ScannerDropDownItem></StyledLink>
                            <StyledLink to="/funding"><ScannerDropDownItem>Funding Bot</ScannerDropDownItem></StyledLink>
                        </ScannerDropDown>
                    )}

                    {isScannerDropDownOpen && !isAuthenticated && (
                        <ScannerDropDown>
                            <StyledLink to="/#"><ScannerDropDownItem onClick={openSign}>InterExchange Arbitrage</ScannerDropDownItem></StyledLink>
                            <StyledLink to="/#"><ScannerDropDownItem onClick={openSign}>Funding Bot</ScannerDropDownItem></StyledLink>
                        </ScannerDropDown>
                    )}
                </ScannerNavItem>
                {/*<NavItem>Rates</NavItem>*/}
                {/*<NavItem>Partners</NavItem>*/}
                <StyledLink to='/dashboard'>
                    <NavItem onClick={closeHamburgerMenu}>Dashboard</NavItem>
                </StyledLink>
                {isMobile && (
                    <>
                        {isAuthenticated ? (
                            <SignInUpButton onClick={() => { onLogOut(); closeHamburgerMenu(); }}>Log out</SignInUpButton>
                        ) : (
                            <SignInUpButton onClick={() => { setIsOpenSign(true); closeHamburgerMenu(); }}>Sign In/Up</SignInUpButton>
                        )}
                        <ToggleButton />
                    </>
                )}
            </NavItems>

            {!isMobile && (
                <RightBlock onClick={onClickRightBlock} ref={ref}>
                    {isAuthenticated ? (
                        <SignInUpButton onClick={() => { onLogOut(); closeHamburgerMenu(); }}>Log out</SignInUpButton>
                    ) : (
                        <SignInUpButton  onClick={() => { setIsOpenSign(true); closeHamburgerMenu(); }}>Sign In/Up</SignInUpButton>
                    )}
                    <ToggleButton />
                </RightBlock>
            )}
        </HeaderWrapper>
    );
};

export default memo(Header);
