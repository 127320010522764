import React from 'react';
import { Typography, Button } from '@mui/material';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import FundingImage from '../../../assets/images/Screenshot 2024-08-19 at 18.27.20.png';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  animation: ${fadeIn} 2s ease-in-out;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px;
  }

  @media (max-width: 480px) {
    padding: 20px;
  }
`;

const TextSection = styled.div`
  max-width: 60%;
  margin-right: 5%; /* Add margin to create space between TextSection and ImageSection */
  animation: ${fadeIn} 2s ease-in-out;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-right: 0;
  }
`;


const Title = styled(Typography)`
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 25px;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
`;


const Subtitle = styled(Typography)`
  font-size: 1.5rem;
  margin-bottom: 25px;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    margin-bottom: 15px;
  }
`;

const ArbitrageText = styled(Typography)`
  margin-bottom: 20px; /* Space between each paragraph */

  @media (max-width: 768px) {
    margin-bottom: 15px; /* Adjusted for smaller screens */
  }

  @media (max-width: 480px) {
    margin-bottom: 10px; /* Adjusted for the smallest screens */
  }
`;

const ImageSection = styled.div`
  max-width: 45%;
  animation: ${fadeIn} 2s ease-in-out;

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 20px;
  }

  @media (max-width: 480px) {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

const StyledList = styled.ul`
  margin-left: 20px;

  @media (max-width: 768px) {
    margin-left: 10px;
  }

  @media (max-width: 480px) {
    margin-left: 5px;
  }
`;

const StyledListItem = styled.li`
  margin-bottom: 10px;

  @media (max-width: 768px) {
    margin-bottom: 8px;
  }

  @media (max-width: 480px) {
    margin-bottom: 5px;
  }
`;

const StyledButton = styled(Button)`
  background: linear-gradient(45deg, #ff6f61, #de1d23);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  padding: 15px 30px;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #de1d23, #ff6f61);
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 10px 20px;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    padding: 8px 15px;
    margin-bottom: 15px;
  }
  
`;

const FundingRateArbitrageSection = () => {
    return (
        <div>
            <HeaderSection>
                <TextSection>
                    <Title variant="h2">Simple Explanation of Funding Rate Arbitrage</Title>
                    <Subtitle variant="h5">
                        Funding rate arbitrage is a strategy for earning profits by exploiting differences in funding rates between futures contracts or between futures and spot markets in cryptocurrency trading.
                    </Subtitle>
                    <ArbitrageText variant="body1">
                        <strong>Arbitrage Between Futures on Different Exchanges:</strong> You open a long (buy) position on one exchange where the funding rate is lower and a short (sell) position on another exchange where the rate is higher. Profit is made from the difference in funding rates.
                    </ArbitrageText>
                    <ArbitrageText variant="body1">
                        <strong>Arbitrage Between Spot and Futures:</strong> When the funding rate is positive, you buy on the spot market and simultaneously sell an equivalent amount in futures to earn stable income from the funding rate. If the rate is negative, you do the opposite — sell on the spot market and buy in futures.
                    </ArbitrageText>
                    <ArbitrageText variant="body1">
                        <strong>Earning from Price Spreads:</strong> Another way is to profit from the price difference between the spot market and futures, known as "basis trading." For example, if the futures price is higher, you sell it and buy the asset on the spot market.
                    </ArbitrageText>
                    <StyledButton>Learn More About Funding Rate Arbitrage</StyledButton>
                </TextSection>
                <ImageSection>
                    <img src={FundingImage} alt="Funding Rate Arbitrage" />
                </ImageSection>
            </HeaderSection>
        </div>
    );
};

export default FundingRateArbitrageSection;
