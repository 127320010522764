import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MediaQueries, ScreenType } from 'enums/ui';

import { selectScreenType } from 'store/selectors/ui';
import { setUIMode } from 'store/slices/ui';

export const useUIStoreSync = () => {
  const dispatch = useDispatch();
  const screenType = useSelector(selectScreenType);

  const calculateHeight = useCallback(() => {
    const height = window.innerHeight * 0.01;

    document.documentElement.style.setProperty('--vh', `${height}px`);
  }, []);

  const calculateWidth = useCallback(() => {
    const width = window.innerWidth;

    let actualScreenType: ScreenType;

    if (width >= parseInt(MediaQueries.Desktop, 10)) {
      actualScreenType = ScreenType.Desktop;
    } else if (width >= parseInt(MediaQueries.Tablet, 10)) {
      actualScreenType = ScreenType.Tablet;
    } else if (width >= parseInt(MediaQueries.MobileLandscape, 10)) {
      actualScreenType = ScreenType.MobileLandscape;
    } else {
      actualScreenType = ScreenType.Mobile;
    }

    if (actualScreenType !== screenType) {
      dispatch(setUIMode({ screenType: actualScreenType }));
    }
  }, [dispatch, screenType]);

  const onResize = useCallback(() => {
    calculateHeight();
    calculateWidth();
  }, [calculateHeight, calculateWidth]);

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize, dispatch]);

  return null;
};
