import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import { isAfter } from 'date-fns';
import styled from 'styled-components';

import {
  ACCESS_TOKEN,
  ACCESS_TOKEN_EXPIRATION_DATE,
  REFRESH_TOKEN,
  REFRESH_TOKEN_EXPIRATION_DATE, RouteLocal,
} from 'constants/common';
import { useUIStoreSync } from 'hooks/useUIStoreSync';

import {loginFailure, loginSuccess} from 'store/slices/auth';

import CircleSpinner from 'components/CircleSpinner';

import { palette } from 'styles/palette';
import {toast} from "react-toastify";
import {saveToken} from "utils/auth";
import LoadingScreen from "components/Loader/Loader";

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  background-color: ${({ theme }) => theme.colors.bodyBackground};
`;

const InitializeApp: FC = ({ children }) => {
  useUIStoreSync();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [searchParams] = useSearchParams();

  const { pathname, search } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const token = localStorage.getItem(ACCESS_TOKEN);
        if (!token) {
          navigate('/');
          return;
        }

        const response = await fetch(`${RouteLocal}/verify_token`, {
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'authorization': `${token}`,
          },
        });

        if (response.status !== 201) {
          navigate('/');
          toast('Session expired or invalid token.', { autoClose: 10_000 });
          return;
        }

        const data = await response.json();
        const { user } = data;

        saveToken(token);
        dispatch(loginSuccess({ user, token }));
        navigate(`${pathname}${search}`);

      } catch (error: any) {
        dispatch(loginFailure(error.message));
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [dispatch]);

  if (isLoading) {
    return (
      <LoaderContainer>
        <LoadingScreen text="Please wait..." size={48} color="#fff" />
      </LoaderContainer>
    );
  }

  return <>{children}</>;
};

export default InitializeApp;
