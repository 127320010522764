import {useEffect, useState} from 'react';
import {ACCESS_TOKEN, RouteLocal} from "constants/common";
import {toast} from "react-toastify";
import {saveToken} from "utils/auth";
import {loginFailure, loginSuccess, logout} from "store/slices/auth";
import {useDispatch} from "react-redux";

function useCustomAuthHook() {
    const [valid, setValid] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        const checkAuth = async () => {
            try {
                const token = localStorage.getItem(ACCESS_TOKEN);
                if (!token) {
                    return;
                }

                const response = await fetch(`${RouteLocal}/verify_token`, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `${token}`,
                    },
                });

                if (response.status !== 201) {
                    setValid(false)
                    return;
                }

                const data = await response.json();
                const { user } = data;

                saveToken(token);
                dispatch(loginSuccess({ user, token }));

            } catch (error: any) {
                dispatch(logout);
            }
        };

        checkAuth();
    }, []);

    return [valid];
}

export default useCustomAuthHook;