import { DefaultTheme } from 'styled-components';

import { palette } from 'styles/palette';

export const darkTheme: DefaultTheme = {
  colors: {
    primary: palette.red,
    secondary: '',
    accent: '',
    primaryGradientEnd: palette.deepRed,
    white: palette.light,
    black: palette.dark,
    authBackground: '#292929',
    bodyBackground: '#292929',
    paginationBackground: palette.darkGrey,
    shadow: palette.transparent,
    separateLine: palette.light,
    text: {
      primary: palette.light,
      secondary: palette.red,
    },
    status: {
      info: '#1a96fe',
      success: '#24D440',
      error: '#ef4141',
      warning: '#d7a148',
    },
    card: {
      icon: palette.light,
      background: palette.darkGrey,
    },
    loan: {
      initiated: '#ff9000',
      submitted: '#00a3ff',
      preApproved: '#0057ff',
      provideAddress: '#ad00ff',
      readyToSign: '#ad00ff',
      signing: '#16cf4a',
      signed: '#16cf4a',
      underwriting: '#22d3ee',
      approved: '#1ed045',
      confirmingScheduling: '#1ed045',
      closingScheduled: '#1c9719',
      closing: '#1c9719',
      funded: '#c7c7c7',
      error: '#da2222',
      anonymous: '#da2222',
    },
    buttons: {
      primary: palette.red,
      transparent: palette.transparent,
    },
    controls: {
      text: palette.darkGrey,
      placeholder: palette.lightGrey,
      label: palette.grey,
      background: palette.backgroundGrey,
      disabledBackground: palette.lightGrey,
      hoveredBackground: palette.backgroundLightGrey,
    },
  },
  coefficients: {
    primaryHovered: 0.1,
    primaryDisabled: 0.2,
    primaryTextHovered: 0.14,
    primaryErrorHovered: 0.15,
    secondaryHovered: 0.14,
    secondaryDisabled: 0.18,
    controlsHovered: 0.13,
    controlsDisabled: 0.09,
  },
};
