import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import { IconButton, Typography, Container } from '@mui/material';
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';
import {HeadText, Slide, SlideContainer} from "../Main.styles";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CompareArrowsOutlinedIcon from '@mui/icons-material/CompareArrowsOutlined';
import ElectricBoltOutlinedIcon from '@mui/icons-material/ElectricBoltOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import VerifiedIcon from '@mui/icons-material/Verified';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
const slidesData = [
    {
        title: 'Integration with Top Exchanges',
        icon: <CompareArrowsOutlinedIcon />,
        description: 'Our platform synchronizes with 17 leading cryptocurrency exchanges, providing you with the most up-to-date information and spread analysis for over 6500 coins.'
    },
    {
        title: 'Convenient and Flexible Filters',
        icon: <FilterAltIcon />,
        description: 'Customize your trades according to your preferences using intuitive filters: select the desired trade amount, minimum spread, specific exchanges, fees, and many other parameters.'
    },
    {
        title: 'Lightning-fast Data Updates',
        icon: <ElectricBoltOutlinedIcon />,
        description: 'We monitor exchanges around the clock and update spread data every 5 seconds, ensuring you have access to the freshest and most accurate information.'
    },
    {
        title: 'Trade Amount Optimization',
        icon: <TrendingUpIcon />,
        description: 'Our service analyzes your deposit and calculates the optimal trade amount, helping you achieve maximum profit.'
    },
    {
        title: 'Deposit and Withdrawal Verification',
        icon: <VerifiedIcon />,
        description: 'The platform thoroughly checks the possibilities for depositing and withdrawing coins and matches transfer networks across different exchanges, ensuring seamless transactions.'
    },
    {
        title: 'Comprehensive Fee Calculation',
        icon: <RequestQuoteIcon />,
        description: 'We calculate all types of fees—transfer and spot—providing you with net spread data after deducting all costs so you can accurately assess your profit.'
    },
    // {
    //     title: 'Effective Risk Hedging',
    //     icon: <ElectricBoltOutlinedIcon />,
    //     description: 'Our system supports hedging, helping you minimize price change risks when transferring coins. We check and mark coins available for hedging.'
    // },
    // {
    //     title: 'Convenient Spread Notifications',
    //     icon: <ElectricBoltOutlinedIcon />,
    //     description: 'Receive spread notifications even when you are away from your computer. We send alerts to your browser and Telegram, keeping you updated on market changes in real-time.'
    // },
    // {
    //     title: 'Coin Transfer Time Estimation',
    //     icon: <ElectricBoltOutlinedIcon />,
    //     description: 'The platform shows the estimated time for transferring coins between exchanges, helping you plan trades more efficiently and avoid delays.'
    // },

    // Add more slides as needed
];

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;



const Slider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [animation, setAnimation] = useState(fadeIn);
    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const handleNext = useCallback(() => {
        setAnimation(fadeOut);
        setTimeout(() => {
            setCurrentSlide((prev) => (prev + 1) % slidesData.length);
            setAnimation(fadeIn);
        }, 500);
    }, []);

    const handlePrev = useCallback(() => {
        setAnimation(fadeOut);
        setTimeout(() => {
            setCurrentSlide((prev) => (prev - 1 + slidesData.length) % slidesData.length);
            setAnimation(fadeIn);
        }, 500);
    }, []);

    const resetTimer = useCallback(() => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }
        timerRef.current = setInterval(handleNext, 15000);
    }, [handleNext]);

    useEffect(() => {
        resetTimer();
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, [resetTimer]);

    return (
        <>
        <HeadText>Benefits:</HeadText>
        <Container>
            <SlideContainer>
                <Slide key={currentSlide} animation={animation}>
                    <IconButton>
                        {slidesData[currentSlide].icon}
                    </IconButton>
                    <Typography variant="h4" gutterBottom>
                        {slidesData[currentSlide].title}
                    </Typography>
                    <Typography variant="body1">
                        {slidesData[currentSlide].description}
                    </Typography>
                </Slide>
                <IconButton
                    onClick={() => {
                        handlePrev();
                        resetTimer();
                    }}
                    style={{ position: 'absolute', left: 10, top: '50%' }}
                >
                    <ArrowBackIos />
                </IconButton>
                <IconButton
                    onClick={() => {
                        handleNext();
                        resetTimer();
                    }}
                    style={{ position: 'absolute', right: 10, top: '50%' }}
                >
                    <ArrowForwardIos />
                </IconButton>
            </SlideContainer>
        </Container>
        </>
    );
};

export default Slider;