import { FC, SVGProps } from 'react';

const LogoSVG: FC<SVGProps<SVGSVGElement>> = ({ color, className, width, height }) => {
    // Increase the size even more
    const finalWidth = Number.isFinite(width) ? width : 200;
    const finalHeight = Number.isFinite(height) ? height : 400;

    return (
        <svg
            className={className}
            fill="none"
            height={finalHeight}
            viewBox="0 0 55 79"  // Update the viewBox to match the dimensions of the SVG content
            width={finalWidth}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M14 80L26 26" stroke="#DE1D23" />
            <path d="M41.0556 79.5654L27 18" stroke="#DE1D23" />
            <path d="M3.28125 78V36.2031H3.98438L7.42188 44.6406V78H3.28125ZM3.28125 25.7344V23.3125H6.875L26.7969 71.5938L42.5781 35.9688H43.3594V44.5625L28.5938 78H25L3.28125 25.7344ZM46.9531 78V23.3125H51.0938V78H46.9531Z" fill="#E1E1E1"/>
        </svg>
    );
};

export default LogoSVG;
