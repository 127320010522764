import React, {useEffect, useState} from 'react';
import styled, { keyframes } from 'styled-components';
import { Button as BootstrapButton, Spinner } from 'react-bootstrap';
import { Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import {useSelector} from "react-redux";
import {selectAuthData} from "store/selectors/auth";
import {useLocation, useNavigate} from 'react-router-dom';
import {RouteLocal} from "constants/common";
import {toast} from "react-toastify";
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  animation: ${fadeIn} 2s ease-in-out;
  background: linear-gradient(45deg, #f3ec78, #af4261);
  color: white;
  border-radius: 10px;
  margin-bottom: 20px;
`;

const Title = styled(Typography)`
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Subtitle = styled(Typography)`
  font-size: 1.5rem;
  animation: ${fadeIn} 2s ease-in-out;
`;

const PaymentContainer = styled.div`
  padding: 50px;
  animation: ${fadeIn} 2s ease-in-out;
`;

const PlanDetails = styled.div`
  text-align: center;
  margin-bottom: 30px;
`;

const PaymentMethods = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

interface MethodProps {
    selected: boolean;
}

const Method = styled.div<MethodProps>`
  margin: 10px 0;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  border: 2px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s, border-color 0.3s;

  

  &:hover {
    border-color: #af4261;
  }
`;


const ProceedButton = styled(BootstrapButton)`
  background: linear-gradient(45deg, #ff6f61, #de1d23);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  padding: 15px 30px;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #de1d23, #ff6f61);
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
`;

const QRCodeContainer = styled.div`
  margin: 20px 0;
`;

const PaymentInstructions = styled.div`
  text-align: center;
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
`;

const AddressInput = styled.input`
  margin: 10px 0;
  padding: 10px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ddd;
  border-radius: 5px;
`;
const validPlans = ['inter-exchange-arbitrage', 'funding-bot', 'all-inclusive'];
const validDurations = ['month', 'lifetime'];
const Payment: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const plan = params.get('plan');
    const price = params.get('price');
    const duration = params.get('duration');

    const [selectedPlan, setSelectedPlan] = useState('');
    const [paymentMethod, setPaymentMethod] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<string | null>(null);
    const { userId, user: {email: email }, token } = useSelector(selectAuthData);
    const [senderAddress, setSenderAddress] = useState<string>('');

    useEffect(() => {
        if (!plan || !price || !duration) {
            setError('Missing plan, price, or duration');
            navigate('/pricing'); // Redirect to pricing if params are missing
            return;
        }

        if (!validPlans.includes(plan) || isNaN(parseFloat(price)) || !validDurations.includes(duration)) {
            setError('Invalid plan, price, or duration');
            navigate('/pricing'); // Redirect to pricing if params are invalid
        } else {
            setSelectedPlan(`${plan.charAt(0).toUpperCase() + plan.slice(1)} Plan`);
        }
    }, [plan, price, duration, navigate]);
    const handlePaymentMethodSelection = (method: string) => {
        setPaymentMethod(method);
        setError(null);  // Clear any previous error when a new method is selected
    };



    const getPaymentInstructions = (method: string) => {
        switch (method) {
            case 'TRC':
                return 'Send the payment to TRC wallet: TX36y3JSkpuQLkzGRRKvzFqaNhFRQzvR1g.';
            case 'ERC':
                return 'Send the payment to ERC wallet: 0xbd052e1dabb61c5479f8e56901ff43c1d9a4d3a0.';
            case 'BEP':
                return 'Send the payment to BEP wallet: 0xbd052e1dabb61c5479f8e56901ff43c1d9a4d3a0.';
            case 'Revolut':
                return 'Send the payment via Revolut to account: +123456789.';
            case 'Wise':
                return 'Send the payment via Wise to account: wise@domain.com.';
            case 'Bank Transfer':
                return 'Send the payment to the following bank account: IBAN XXXXXXX, SWIFT XXXXX.';
            default:
                return '';
        }
    };

    const getQRCodeValue = (method: string) => {
        switch (method) {
            case 'TRC':
                return 'TX36y3JSkpuQLkzGRRKvzFqaNhFRQzvR1g';
            case 'ERC':
                return '0xbd052e1dabb61c5479f8e56901ff43c1d9a4d3a0';
            case 'BEP':
                return '0xbd052e1dabb61c5479f8e56901ff43c1d9a4d3a0';
            default:
                return '';
        }
    };

    const handleProceedToPayment = async () => {
        setLoading(true);
        setError(null);
        setSuccess(null);
        console.log("user after destruct",email, token)
        if (!senderAddress) {
            setError('Please enter your payment address.');
            setLoading(false);
            return;
        }
        const paymentData = {
            data: {
                userId,
                addressSender: senderAddress,
                networkSender: paymentMethod,
                sum: paymentMethod === 'TRC' || paymentMethod === 'ERC' || paymentMethod === 'BEP' ? `${price}USDT` : `${price}USDT`, // Example sum, adjust accordingly
                typeSubscribe: selectedPlan,
                email: email,
            },
        };

        try {
            const response = await fetch(`${RouteLocal}/payment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `${token}`
                },
                body: JSON.stringify(paymentData)
            });

            if (!response.ok) {
                toast(`Network response was not ok: ${response.statusText}`, { autoClose: 10_000});
            }else {
                console.log(response.json());
            }
            setSuccess('You have confirmed your subscription payment. We will check your funds as soon as possible and automatically activate your subscription.');
        } catch (error: any) {
            console.log(error)
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <PaymentContainer>
            <HeaderSection>
                <Title>{selectedPlan}</Title>
                <Subtitle>{price} USDT / {duration}</Subtitle>
            </HeaderSection>

            <PlanDetails>
                <Subtitle>Select Payment Method:</Subtitle>
                <PaymentMethods>
                    {['TRC', 'ERC', 'BEP', 'Revolut', 'Wise', 'Bank Transfer'].map((method) => (
                        <Method
                            key={method}
                            selected={paymentMethod === method}
                            onClick={() => handlePaymentMethodSelection(method)}
                        >
                            {method}
                        </Method>
                    ))}
                </PaymentMethods>
            </PlanDetails>

            {paymentMethod && (
                <PaymentInstructions>
                    <Subtitle>Payment Instructions for {paymentMethod}:</Subtitle>
                    <p>{getPaymentInstructions(paymentMethod)}</p>
                    <AddressInput
                        type="text"
                        placeholder="Enter your payment address"
                        value={senderAddress}
                        onChange={(e) => setSenderAddress(e.target.value)}
                    />
                    {(paymentMethod === 'TRC' || paymentMethod === 'ERC' || paymentMethod === 'BEP') && (
                        <QRCodeContainer>
                            <QRCode value={getQRCodeValue(paymentMethod)} />
                        </QRCodeContainer>
                    )}
                    <ProceedButton onClick={handleProceedToPayment} disabled={loading}>
                        {loading ? <Spinner as="span" animation="border" size="sm" /> : 'I paid'}
                    </ProceedButton>
                </PaymentInstructions>
            )}

            {error && <p style={{ color: 'red' }}>{error}</p>}
            {success && <p style={{ color: 'green' }}>{success}</p>}
        </PaymentContainer>
    );
};

export default Payment;
