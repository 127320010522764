import { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer as ReactToastContainer } from 'react-toastify';

import { selectIsMobileLandscapeAndLower } from 'store/selectors/ui';

import 'react-toastify/dist/ReactToastify.css';

const ToastContainer: FC = () => {
  const isMobileView = useSelector(selectIsMobileLandscapeAndLower);

  const getToastClassName = useCallback(
    ({ position, defaultClassName }) =>
      position === 'bottom-center'
        ? `${defaultClassName} customToast clipboardToast`
        : `${defaultClassName} customToast`,
    [],
  );

  const getToastContainerClassName = useCallback(
    ({ position, defaultClassName }) =>
      position === 'bottom-center'
        ? `clipboardToastContainer ${defaultClassName}`
        : `toastContainer ${defaultClassName}`,
    [],
  );

  return (
    <ReactToastContainer
      hideProgressBar
      autoClose={3000}
      className={getToastContainerClassName}
      closeButton={false}
      limit={isMobileView ? 3 : 6}
      position="top-right"
      toastClassName={getToastClassName}
    />
  );
};

export default memo(ToastContainer);
