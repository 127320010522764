// @ts-nocheck
import {createContext, FC, useContext} from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { useDarkMode } from 'hooks/useDarkMode';

import { StyledGlobal } from 'styles/globalStyles';
import { darkTheme } from 'styles/themes/dark';
import { lightTheme } from 'styles/themes/light';
const ThemeToggleContext = createContext({ isDarkMode: false, toggleDarkMode: () => {} });

export const useTheme = () => useContext(ThemeToggleContext);
const ThemeProvider: FC = ({ children }) => {
    const { isDarkMode, toggleDarkMode } = useDarkMode();


    // @ts-ignore
    return (
        // @ts-ignore
        <ThemeToggleContext.Provider value={{ isDarkMode, toggleDarkMode }}>
        <StyledThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
            <StyledGlobal/>
            <>
                {children}
            </>
        </StyledThemeProvider>
        </ThemeToggleContext.Provider>
    );
};

export default ThemeProvider;

