import React, { FC } from 'react';
import { useTheme } from './ThemeProvider';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
const ToggleButton: FC = () => {
    const { isDarkMode, toggleDarkMode } = useTheme();

    return (
        <button onClick={toggleDarkMode}>
            {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
        </button>
    );
};

export default ToggleButton;
