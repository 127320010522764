import { configureStore } from '@reduxjs/toolkit';


import { authSlice } from 'store/slices/auth';
import { UIReducer } from 'store/slices/ui';
import profitReducer from './slices/profit';

const rootReducer = {
  authSlice: authSlice.reducer,
  ui: UIReducer,
  profit: profitReducer,
};

// добавил profit и нужен ли для него middleware?
export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;