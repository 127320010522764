import { FC, useState } from "react";
import styled from "styled-components";
import { Typography, Button } from "@mui/material";
import { RouteLocal } from "constants/common";

// Container for the entire page
const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
`;

// Container for the form content
const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 40px; /* Increased padding */
  background-color: #ffffff;
  border-radius: 12px; /* Slightly rounded corners */
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.15); /* Softer shadow */
  text-align: center;
`;

// Styling for the title
const Title = styled(Typography)`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333333; /* Softer black */
`;

// Styling for the subtitle
const Subtitle = styled(Typography)`
  font-size: 1rem;
  margin-bottom: 30px;
  color: #666666;
`;

// Styling for the form
const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

// Styling for the input fields
const Input = styled.input`
  width: 100%;
  padding: 16px 18px; /* Increased padding */
  margin-bottom: 25px; /* Increased margin for more spacing */
  border: 1px solid #dddddd;
  border-radius: 6px; /* More rounded corners */
  background-color: #fafafa; /* Slightly lighter background */
  font-size: 1rem;
  color: #333333;

  &:focus {
    outline: none;
    border-color: #de1d23;
    background-color: #ffffff;
    box-shadow: 0 0 0 2px rgba(255, 111, 97, 0.2);
  }
`;

// Styling for the button with an accent color
const StyledButton = styled(Button)`
  width: 100%;
  padding: 16px 18px; /* Increased padding */
  background: linear-gradient(45deg, #ff6f61, #de1d23);
  
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 6px; /* More rounded corners */
  text-transform: none;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #ff574a;
    transform: translateY(-2px); /* Subtle lift effect */
  }

  &:active {
    background-color: #e03d2f;
  }
`;

// Styling for the link
const Link = styled.a`
  margin-top: 20px;
  font-size: 0.9rem;
  color: #de1d23;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const RequestPasswordReset: FC = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        try {
            fetch(`${RouteLocal}/request-password-reset`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            }).then((response) => {
                alert('Password reset email sent');
            });
        } catch (error) {
            alert('Error sending password reset email');
        }
    };

    return (
        <PageContainer>
            <ContentSection>
                <Title variant="h1">Reset Your Password</Title>
                <Subtitle variant="h5">To get started, enter your email address below.</Subtitle>
                <Form onSubmit={handleSubmit}>
                    <Input
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <StyledButton type="submit">Continue</StyledButton>
                </Form>
            </ContentSection>
        </PageContainer>
    );
};

export default RequestPasswordReset;
