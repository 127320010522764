// slices/profitSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {ACCESS_TOKEN, RouteLocal} from "constants/common";

interface ProfitState {
    data: any[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: ProfitState = {
    data: [],
    status: 'idle',
    error: null,
};

interface FetchArbFundingArgs {
    email: string;
}

export const fetchArbFundingData = createAsyncThunk<any, FetchArbFundingArgs>(
    'profit/fetchArbFundingData',
    async ({ email }) => {
        console.log("email in fetchArbFundingData",email)
        const token = localStorage.getItem(ACCESS_TOKEN);
        const response = await fetch(`${RouteLocal}/profitFunding?email=${encodeURIComponent(email)}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `${token}`
            }
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return  await response.json();
    }
);

export const fetchInterExchangeFundingData = createAsyncThunk<any, FetchArbFundingArgs>(
    'profit/fetchInterExchangeFundingData',
    async ({ email }) => {
        const token = localStorage.getItem(ACCESS_TOKEN);
        if (!token) {
            throw new Error('Token not found');
        }
        const response = await fetch(`${RouteLocal}/profit?email=${encodeURIComponent(email)}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'authorization': `${token}`
            }
        });
        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
    }
);


const profitSlice = createSlice({
    name: 'profit',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchArbFundingData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchArbFundingData.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchArbFundingData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            })
            .addCase(fetchInterExchangeFundingData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchInterExchangeFundingData.fulfilled, (state, action: PayloadAction<any[]>) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(fetchInterExchangeFundingData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            });
    },
});

export default profitSlice.reducer;
