import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FormControlLabel, Checkbox } from '@mui/material';
import { Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector } from 'react-redux';
import { selectAuthData } from 'store/selectors/auth';
import LoadingScreen from 'components/Loader/Loader';
import { ACCESS_TOKEN, RouteLocal } from 'constants/common';
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

// Keyframes for fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Container styling
const Container = styled.div`
  width: 100%;
  max-width: none;
  padding: 0 20px;
  box-sizing: border-box;
`;

// Title styling
const Title = styled(Typography)`
  font-size: 3rem;
  font-weight: 900;
  text-align: center;
  margin-bottom: 40px;
  font-family: 'Roboto', sans-serif;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

// Section title styling
const SectionTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 25px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
`;

// Subtitle styling
const SubTitle = styled.h3`
  font-size: 1.8rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text.primary};
  text-align: left;
  margin-bottom: 20px;
  margin-top: 40px;
  font-family: 'Roboto', sans-serif;

  &::before {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    background: linear-gradient(45deg, #ff6f61, #de1d23);
    margin-bottom: 8px;
  }
`;

// Table container styling
const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-top: 40px;
  background: ${({ theme }) => theme.colors.card.background};
  border-radius: 15px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #de1d23;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
`;

const DealTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: 'Roboto', sans-serif;

  thead th {
    background: #de1d23;
    color: white;
    padding: 12px 20px; /* Added right padding */
    text-align: left;
    cursor: pointer;
    position: relative;
    transition: background 0.3s;

    &:hover {
      background: #ff6f61;
    }

    &.sorted-asc:after, &.sorted-desc:after {
      content: '';
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: white transparent transparent transparent;
      margin-left: 8px;
      display: inline-block;
    }

    &.sorted-asc:after {
      transform: translateY(-50%) rotate(180deg);
    }

    &.sorted-desc:after {
      transform: translateY(-50%);
    }

    &.sortable {
      &:before {
        content: '⇅';
        position: absolute;
        right: 12px; /* Positioned closer to the right edge */
        top: 50%;
        transform: translateY(-50%);
        font-size: 1.2rem;
        color: #ffffffb3;
        transition: color 0.3s;
        font-weight: bold;
        font-family: Arial, sans-serif;
        padding-left: 14px; /* Added padding between text and icon */
      }
      
      &:hover::after {
        opacity: 1;
      }
    }
  }

  tbody td {
    padding: 12px;
    border-bottom: 1px solid #ecf0f1;

    
  }
  

  @media (max-width: 768px) {
    th, td {
      padding: 10px 8px;
      font-size: 0.9rem;
    }
  }

  @media (max-width: 480px) {
    thead th,
    tbody td {
      font-size: 0.85rem;
      padding: 10px 12px;
    }

    tbody tr {
      border-radius: 8px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    }
  }
`;



// Row styling with animation
const DealRow = styled.tr`
  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
  }

  &.added {
    animation: rowAdded 0.5s ease-in-out;
  }

  @keyframes rowAdded {
    from {
      background-color: #e0ffe0;
      transform: scale(1.02);
    }
    to {
      background-color: transparent;
      transform: scale(1);
    }
  }
`;

// Exchange selection styling
const ExchangeSelection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  margin-bottom: 50px;
`;

// Checkbox styling
const ExchangeCheckbox = styled(FormControlLabel)`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  label {
    margin-left: 10px;
    font-size: 1.3rem;
    font-family: 'Roboto', sans-serif;
    transition: color 0.3s ease;
  }

  input {
    width: 24px;
    height: 24px;
    accent-color: #ff6f61;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  &:hover label {
    color: #ff6f61;
  }
`;

// Filter section styling
const FilterSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;

  input {
    flex: 1;
    margin: 10px;
    border-radius: 12px;
    padding: 12px;
    border: 1px solid #bdc3c7;
    font-size: 1rem;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    font-family: 'Roboto', sans-serif;

    &:focus {
      box-shadow: 0 0 8px rgba(255, 111, 97, 0.5);
      transform: scale(1.02);
      border-color: #ff6f61;
    }
  }
`;

const FundingPage: React.FC = () => {
    const [spotFutures, setSpotFutures] = useState(false);
    const [selectedSpotExchanges, setSelectedSpotExchanges] = useState<string[]>([]);
    const [selectedFuturesExchanges, setSelectedFuturesExchanges] = useState<string[]>([]);

    const [sortConfig, setSortConfig] = useState<{ key: string | null, direction: 'ascending' | 'descending' }>({ key: null, direction: 'descending' });
    const navigate = useNavigate();
    const handleSort = (key: string) => {
        const newDirection = sortConfig.key === key && sortConfig.direction === 'ascending'
            ? 'descending'
            : 'ascending';

        setSortConfig({
            key,
            direction: newDirection,
        });
    };



    const { user } = useSelector(selectAuthData);
    const [data, setData] = useState<any>(null);
    const email = user?.email;

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;
        const fetchData = async () => {
            const token = localStorage.getItem(ACCESS_TOKEN);
            if (!token) {
                throw new Error('Token not found');
            }
            const response = await fetch(`${RouteLocal}/profitFunding?email=${encodeURIComponent(email)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `${token}`,
                },
            });
            if (!response.ok) {
                toast(`Network response was not ok: ${response.statusText}`, { autoClose: 10_000 });

                if(response.status === 401) {
                    return navigate('/');
                }
            }
            const resData = await response.json();
            setData(resData);
        };

        fetchData();
        interval = setInterval(fetchData, 15000);

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, []);

    if (!data) {
        return   <LoadingScreen text="Please wait..." size={48} color="#fff" />;
    }

    const handleSpotCheckboxChange = (exchange: string) => {
        setSelectedSpotExchanges(prev =>
            prev.includes(exchange)
                ? prev.filter(item => item !== exchange)
                : [...prev, exchange]
        );
    };

    const handleFuturesCheckboxChange = (exchange: string) => {
        setSelectedFuturesExchanges(prev =>
            prev.includes(exchange)
                ? prev.filter(item => item !== exchange)
                : [...prev, exchange]
        );
    };
     const addSymbol = (str: string, symb: string): string => {
        const arr = str.split('');
        return `${arr.slice(0, str.length - 4).join('')}${symb}${arr.splice(-4, 4).join('')}`
    }

    function formatToLowerCase(nameCoin: any) {
        return nameCoin.toLowerCase();
    }
     const formatCoinName = (coinName: string): string => {
        // Define the substring you want to find (e.g., 'USDT')
        const quoteCurrency = "USDT";

        // Find the index of the quoteCurrency in the coinName
        const index = coinName.indexOf(quoteCurrency);

        if (index === -1) {
            // If the quoteCurrency is not found, return the original coinName or handle as needed
            return coinName;
        }

        // Extract the base currency and concatenate with the formatted result
        const baseCurrency = coinName.substring(0, index);
        const formattedName = `${baseCurrency}-${quoteCurrency}-SWAP`;

        return formattedName;
    }
    const makeExchangeLink = (nameEx: string, nameCoin: string): string => {
        let link = '';
        switch (nameEx) {
            case 'binance' :
                link = `https://www.binance.com/en/trade/${addSymbol(nameCoin, '_')}?type=spot`
                break;
            case 'kucoin' :
                link = `https://www.kucoin.com/trade/${addSymbol(nameCoin, '-')}`
                break;
            case 'bybit' :
                link = `https://www.bybit.com/en/trade/spot/${addSymbol(nameCoin, '/')}`
                break;
            case 'bitget' :
                link = `https://www.bitget.com/spot/${nameCoin}`
                break;
            case 'mexc' :
                link = `https://www.mexc.com/exchange/${addSymbol(nameCoin, '_')}`
                break;
            case 'gate' :
                link = `https://www.gate.io/trade/${addSymbol(nameCoin, '_')}`
                break;
            case 'huobi':
                link = `https://www.htx.com/trade/${formatToLowerCase(addSymbol(nameCoin, '_'))}?type=spot`;
                break;
            case 'bitmart' :
                link = `https://www.bitmart.com/trade/en-US?symbol=${addSymbol(nameCoin, '_')}`
                break;
            case 'coinex' :
                link = `https://www.coinex.com/en/exchange/${addSymbol(nameCoin, '-').toLowerCase()}`
                break;
            case 'poloniex' :
                link = `https://poloniex.com/trade/${addSymbol(nameCoin, '_')}`
                break;
            case 'xt':
                link = `https://www.xt.com/en/trade/${formatToLowerCase(addSymbol(nameCoin, '_'))}`;
                break;
            case 'coinw':
                link = `https://www.coinw.com/spot/${nameCoin}`;
                break;
            case 'hitbtc':
                link = `https://hitbtc.com/${formatToLowerCase(addSymbol(nameCoin, '-to-'))}`;
                break;
            case 'bingx':
                link = `https://bingx.com/en/spot/${nameCoin}/`;
                break;
            case 'probit':
                link = `https://www.probit.com/app/exchange/${addSymbol(nameCoin, '-')}`;
                break;
            case 'tapbit':
                link = `https://www.tapbit.com/spot/exchange/${addSymbol(nameCoin, '_')}`;
                break;
            case 'ascendex':
                link = `https://ascendex.com/en/cashtrade-spottrading/${formatToLowerCase(addSymbol(nameCoin, '/'))}`;
                break;
            case 'okx':
                link = `https://www.okx.com/ru/trade-spot/${addSymbol(nameCoin, '-').toLowerCase()}`;
                break;
        }
        return  link;
    }

    const generateExchangeUrl = (exchange: string, coinName: string): string => {
        switch (exchange) {
            case 'mexc':
                return `https://futures.mexc.com/exchange/${coinName.replace(/USDT$/, '_USDT')}`;
            case 'kucoin':
                return `https://www.kucoin.com/futures/trade/${coinName}M`;
            case 'bitget':
                return `https://www.bitget.com/futures/usdt/${coinName}`;
            case 'binance':
                return `https://www.binance.com/en/futures/${coinName}`;
            case 'coinex':
                return `https://www.coinex.com/en/futures/${formatToLowerCase(addSymbol(coinName, '-'))}`;
            case 'gate':
                return `https://www.gate.io/ru/futures/USDT/${coinName.replace(/USDT$/, '_USDT')}`;
            case 'poloniex':
                const poloniexCoinName = coinName === 'SHIBUSDT' ? `1000${coinName}` : coinName;
                return `https://poloniex.com/futures/trade/${poloniexCoinName}USDTPERP`;
            case 'huobi':
                return `https://www.htx.com/futures/linear_swap/exchange#contract_code=${coinName.replace(/USDT$/, '-USDT')}&contract_type=swap&type=cross`;
            case 'bybit':
                return `https://www.bybit.com/trade/usdt/${coinName}`;
            case 'okx':
                return `https://www.okx.com/ru/trade-swap/${formatCoinName(coinName).toLowerCase()}`;
                break;
            case 'xt':
                return `https://www.xt.com/en/futures/trade/${formatToLowerCase(addSymbol(coinName, '_'))}`;
                break;
            case 'bingx':
                return `https://bingx.com/en/futures/forward/${coinName}/`;
                break;
            default:
                return '#'; // Fallback URL if exchange is not recognized
        }
    };

    const fundingData = data?.data[0].fundings;

    const filteredResults = Object.values(fundingData).flat().filter((item: any) => {
        const isSpotFutures = !item.obj1 || !item.obj2;

        let result;
        if (!spotFutures) {
            const futuresExchanges = [item.obj1?.exName, item.obj2?.exName].filter(Boolean);
            const futuresExchangesLower = futuresExchanges.map(exchange => exchange.toLowerCase());
            const selectedFuturesExchangesLower = selectedFuturesExchanges.map(exchange => exchange.toLowerCase());
            const matchesFuturesExchange = selectedFuturesExchangesLower.length === 0 ||
                futuresExchangesLower.some(futuresExchange => selectedFuturesExchangesLower.includes(futuresExchange));
            result = matchesFuturesExchange && !isSpotFutures;
        } else {
            const spotExchanges = [item.spotExchange, item.futuresExchange].filter(Boolean);
            const spotExchangesLower = spotExchanges.map(exchange => exchange.toLowerCase());
            const selectedSpotExchangesLower = selectedSpotExchanges.map(exchange => exchange.toLowerCase());
            const matchesSpotExchange = selectedSpotExchangesLower.length === 0 ||
                spotExchangesLower.some(spotExchange => selectedSpotExchangesLower.includes(spotExchange));
            result = matchesSpotExchange && isSpotFutures;
        }
        return result;
    }).sort((a: any, b: any) => {
        if (!sortConfig.key) {
            console.warn('SortConfig key is missing.');
            return 0; // Возвращаем 0, чтобы не изменять порядок элементов
        }

        // Разделяем ключ на основной и вложенный
        const [mainKey, subKey] = sortConfig.key.split('.');

        // Получаем значения для сортировки
        const aValueRaw = subKey ? a[mainKey]?.[subKey] : a[mainKey];
        const bValueRaw = subKey ? b[mainKey]?.[subKey] : b[mainKey];

        // Удаляем нечисловые символы и преобразуем в числа
        const cleanValue = (value: any) => {
            if (typeof value === 'string') {
                // Удаляем нечисловые символы, оставляя только цифры, точку и знак минус
                const cleaned = value.replace(/[^0-9.-]/g, '');
                return isNaN(Number(cleaned)) ? 0 : Number(cleaned);
            }
            return isNaN(Number(value)) ? 0 : Number(value);
        };

        const aValue = cleanValue(aValueRaw);
        const bValue = cleanValue(bValueRaw);

        // Проверяем, что преобразование в числа прошло успешно
        if (isNaN(aValue) || isNaN(bValue)) {
            console.error(`Invalid values for key ${sortConfig.key}: a = ${aValueRaw}, b = ${bValueRaw}`);
            return 0; // Если значения некорректные, не изменяем порядок
        }

        if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
        if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
        return 0;
    });

    return (
        <Container>
            <Title variant="h1">Futures Scanner</Title>
            <SectionTitle>Select Exchanges and Configure Parameters</SectionTitle>


            <SubTitle>Futures:</SubTitle>
            <ExchangeSelection>
                {/* Пример использования ExchangeCheckbox для фьючерсных бирж */}
                <ExchangeCheckbox
                    control={<Checkbox value="Binance" onChange={() => handleFuturesCheckboxChange('Binance')} />}
                    label="Binance"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="KuCoin" onChange={() => handleFuturesCheckboxChange('KuCoin')} />}
                    label="KuCoin"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="Mexc" onChange={() => handleFuturesCheckboxChange('Mexc')} />}
                    label="Mexc"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="Bitget" onChange={() => handleFuturesCheckboxChange('Bitget')} />}
                    label="Bitget"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="Bybit" onChange={() => handleFuturesCheckboxChange('Bybit')} />}
                    label="Bybit"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="Poloniex" onChange={() => handleFuturesCheckboxChange('Poloniex')} />}
                    label="Poloniex"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="Gate" onChange={() => handleFuturesCheckboxChange('Gate')} />}
                    label="Gate"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="okx" onChange={() => handleFuturesCheckboxChange('Okx')} />}
                    label="OKX"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="xt" onChange={() => handleFuturesCheckboxChange('XT')} />}
                    label="XT"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="Coinex" onChange={() => handleFuturesCheckboxChange('Coinex')} />}
                    label="Coinex"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="Huobi" onChange={() => handleFuturesCheckboxChange('Huobi')} />}
                    label="Huobi"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="BingX" onChange={() => handleFuturesCheckboxChange('BingX')} />}
                    label="BingX"
                />
                {/* Добавьте остальные фьючерсные биржи по аналогии */}
            </ExchangeSelection>

            <SubTitle>Spot:</SubTitle>
            <ExchangeSelection>
                <ExchangeCheckbox
                    control={<Checkbox value="Binance" onChange={() => handleSpotCheckboxChange('Binance')} />}
                    label="Binance"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="Bybit" onChange={() => handleSpotCheckboxChange('Bybit')} />}
                    label="Bybit"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="KuCoin" onChange={() => handleSpotCheckboxChange('KuCoin')} />}
                    label="KuCoin"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="Mexc" onChange={() => handleSpotCheckboxChange('Mexc')} />}
                    label="Mexc"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="Bitget" onChange={() => handleSpotCheckboxChange('Bitget')} />}
                    label="Bitget"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="Poloniex" onChange={() => handleSpotCheckboxChange('Poloniex')} />}
                    label="Poloniex"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="Gate" onChange={() => handleSpotCheckboxChange('Gate')} />}
                    label="Gate"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="xt" onChange={() => handleSpotCheckboxChange('XT')} />}
                    label="XT"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="okx" onChange={() => handleSpotCheckboxChange('Okx')} />}
                    label="OKX"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="Coinex" onChange={() => handleSpotCheckboxChange('Coinex')} />}
                    label="Coinex"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="Huobi" onChange={() => handleSpotCheckboxChange('Huobi')} />}
                    label="Huobi"
                />
                <ExchangeCheckbox
                    control={<Checkbox value="BingX" onChange={() => handleSpotCheckboxChange('BingX')} />}
                    label="BingX"
                />
            </ExchangeSelection>
            <FilterSection>
                <ExchangeSelection>
                    <ExchangeCheckbox
                        control={<Checkbox checked={spotFutures} onChange={() => setSpotFutures(!spotFutures)} />}
                        label="Spot"
                    />
                    <ExchangeCheckbox
                        control={<Checkbox checked={!spotFutures} onChange={() => setSpotFutures(!spotFutures)} />}
                        label="Futures"
                    />
                </ExchangeSelection>
            </FilterSection>

            <TableContainer>
                <DealTable>
                    <thead>
                    <tr>
                        {spotFutures ? (
                            <>
                                <th>Symbol</th>
                                <th>Exchanges</th>

                                {/* Спреды и комиссии */}
                                <th className="sortable" onClick={() => { handleSort('fundingRate'); }}>Funding Spread</th>
                                <th className="sortable" onClick={() => { handleSort('entrySpread'); }}>Entry Spread</th>
                                <th className="sortable" onClick={() => { handleSort('exitSpread'); }}>Exit Spread</th>
                                <th>Fee</th>

                                {/* Ценовые показатели */}
                                <th>Futures Ask</th>
                                <th>Futures Bid</th>
                                <th>Spot Ask</th>
                                <th>Spot Bid</th>

                                {/* Дополнительные показатели */}
                                <th>Before accrual</th>
                                <th>Spot Fee</th>
                                <th>Short Fee</th>

                            </>

                        ) : (
                            <>
                                <th>Coin</th>
                                <th>Exchanges</th>
                                <th>Best Ask 1</th>
                                <th>Best Bid 1</th>
                                <th>Funding Rate 1</th>
                                <th>Before accrual</th>
                                <th>Best Ask 2</th>
                                <th>Best Bid 2</th>
                                <th>Funding Rate 2</th>
                                <th>Before accrual</th>
                                <th className="sortable" onClick={() => { handleSort('fundingSpread'); }}>Funding Spread</th>
                                <th className="sortable" onClick={() => { handleSort('spreads.entrySpreadToFixed'); }}>Entry Spread</th>
                                <th className="sortable" onClick={() => { handleSort('spreads.exitSpreadToFixed'); }}>Exit Spread</th>
                                <th>Total Fee</th>
                            </>
                        )}
                    </tr>
                    </thead>
                    <tbody>
                    {filteredResults.map((item: any, index: number) => (
                        <DealRow key={index}>
                            {/* Render table rows based on `spotFutures` */}
                            {spotFutures ? (
                                <>
                                    {/* Символ монеты */}
                                    <td>{item.symbol}</td>

                                    {/* Биржи с ссылками */}
                                    <td>
                                        <a
                                            href={generateExchangeUrl(item.futuresExchange, item.symbol)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {item.futuresExchange}
                                        </a>
                                        &nbsp;
                                        <a
                                            href={makeExchangeLink(item.spotExchange, item.symbol)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {item.spotExchange}
                                        </a>
                                    </td>

                                    {/* Спреды и комиссии */}
                                    <td>{item.fundingRate}</td>
                                    <td>{item.entrySpread}</td>
                                    <td>{item.exitSpread}</td>
                                    <td>{item.formattedFee}</td>

                                    {/* Ценовые показатели */}
                                    <td>{item.futuresAsk}</td>
                                    <td>{item.futuresBid}</td>
                                    <td>{item.spotAsk}</td>
                                    <td>{item.spotBid}</td>

                                    {/* Дополнительные показатели */}
                                    <td>{item.nextFundingTime}</td>
                                    <td>{item.longFee}</td>
                                    <td>{item.shortFee}</td>

                                </>

                            ) : (
                                <>
                                    {/* Определяем, какой объект отображать первым */}
                                    {item.longExchange === item.obj1?.exName ? (
                                        <>
                                            <td>{item.obj1?.coinName}</td>
                                            <td>
                                                <a
                                                    href={generateExchangeUrl(item.obj1?.exName, item.obj1?.coinName)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {item.obj1?.exName}
                                                </a>
                                                &nbsp;
                                                <a
                                                    href={generateExchangeUrl(item.obj2?.exName, item.obj2?.coinName)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {item.obj2?.exName}
                                                </a>
                                            </td>
                                            <td>{item.obj1?.bestAsk}</td>
                                            <td>{item.obj1?.bestBid}</td>
                                            <td>{item.obj1?.fundRate}</td>
                                            <td>{item.obj1?.fundingTime}</td>
                                            <td>{item.obj2?.bestAsk}</td> {/* Corrected to Best Ask 2 */}
                                            <td>{item.obj2?.bestBid}</td> {/* Corrected to Best Bid 2 */}
                                            <td>{item.obj2?.fundRate}</td> {/* Corrected to Funding Rate 2 */}
                                            <td>{item.obj2?.fundingTime}</td>
                                        </>
                                    ) : (
                                        <>
                                            <td>{item.obj2?.coinName}</td>
                                            <td>
                                                <a
                                                    href={generateExchangeUrl(item.obj2?.exName, item.obj2?.coinName)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {item.obj2?.exName}
                                                </a>
                                                &nbsp;
                                                <a
                                                    href={generateExchangeUrl(item.obj1?.exName, item.obj1?.coinName)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {item.obj1?.exName}
                                                </a>
                                            </td>
                                            <td>{item.obj2?.bestAsk}</td> {/* Corrected to Best Ask 2 */}
                                            <td>{item.obj2?.bestBid}</td> {/* Corrected to Best Bid 2 */}
                                            <td>{item.obj2?.fundRate}</td> {/* Corrected to Funding Rate 2 */}
                                            <td>{item.obj2?.fundingTime}</td>
                                            <td>{item.obj1?.bestAsk}</td>
                                            <td>{item.obj1?.bestBid}</td>
                                            <td>{item.obj1?.fundRate}</td>
                                            <td>{item.obj1?.fundingTime}</td>
                                        </>
                                    )}
                                    <td>{item.fundingSpread}</td>
                                    <td>{item.spreads?.entrySpreadToFixed}</td>
                                    <td>{item.spreads?.exitSpreadToFixed}</td>
                                    <td>{item.totalTakerFee}</td>
                                </>
                            )}
                        </DealRow>
                    ))}
                    </tbody>
                </DealTable>
            </TableContainer>
        </Container>
    );
};

export default FundingPage;
