import { Component, ReactNode } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { TypographyComponent } from 'enums/ui';

import Button from 'components/Button';
import { Typography } from 'components/Typography';

interface ErrorBoundaryProps {
  children?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(var(--vh, 1vh) * 100);
`;

const Text = styled(Typography)`
  text-align: center;
  margin-bottom: 40px;
`;

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    toast.dismiss();
    this.setState({ hasError: true });

  }

  onButtonClick() {
    window.location.reload();
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Container>
          <Text component={TypographyComponent.H4}>
            OOPS! Well this is awkward... Something went wrong on our end
          </Text>
          <Button onClick={this.onButtonClick}>Reload</Button>
        </Container>
      );
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
