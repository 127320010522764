import { css } from 'styled-components';

import { TypographyComponent, TypographySize, TypographyType, TypographyWeight } from 'enums/ui';
import {
  getColorByVariant,
  resolveFontSize,
  resolveLineHeight,
  resolveWeight,
} from 'utils/typography';

import { Theme } from 'components/Typography/Typography.types';

export interface TypographyStylesProps {
  component?: TypographyComponent;
  size?: TypographySize;
  weight?: TypographyWeight;
  type?: TypographyType;
}

export interface TypographyStylesCombinedProps extends TypographyStylesProps, Theme {}

// by default:
// component - 'p'
// size - 16px (depends on component)
// weight - h1 - 700, (h2-h5) - 600, other 300
export const typographyStyles = css`
  font-size: ${({ component, size }: TypographyStylesCombinedProps) =>
    resolveFontSize(component, size)};
  line-height: ${({ component, size }: TypographyStylesCombinedProps) =>
    resolveLineHeight(component, size)};
  font-weight: ${({ component, weight }: TypographyStylesCombinedProps) =>
    resolveWeight(component, weight)};
  letter-spacing: 0;
  color: ${({ type, theme }: TypographyStylesCombinedProps) => getColorByVariant(type, theme)};
  word-break: break-word;

  padding-top: 2px;
`;
