import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaSpinner } from 'react-icons/fa';

interface LoadingScreenProps {
    text?: string; // Текст, который будет отображаться в загрузчике
    size?: number; // Размер иконки спиннера
    color?: string; // Цвет текста и спиннера
}

// Define the spin animation for the icon
export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Define the fadeIn animation for the loader background
export const fadeIn = keyframes`
  0% { opacity: 0; transform: scale(0.9); }
  100% { opacity: 1; transform: scale(1); }
`;

// Styled container to center the loader
export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

// Styled div for the loader
export const Loader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 400px;
  background: linear-gradient(45deg, #de1d23, #ff6f61);
  color: #fff;
  font-size: 18px;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  animation: ${fadeIn} 0.8s ease-in-out;
`;

// Styled icon with spin animation
export const SpinnerIcon = styled(FaSpinner)<{ size: number, color: string }>`
  font-size: ${(props) => props.size}px;
  margin-bottom: 12px;
  animation: ${spin} 1.2s linear infinite;
  color: ${(props) => props.color};
`;

// Loader component
const LoadingScreen: React.FC<LoadingScreenProps> = ({ text = "Loading...", size = 36, color = "#fff" }) => {
    return (
        <CenteredContainer>
            <Loader>
                <SpinnerIcon size={size} color={color} />
                {text}
            </Loader>
        </CenteredContainer>
    );
};

export default LoadingScreen;
