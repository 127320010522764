import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled, { keyframes } from 'styled-components';
import {Row, Col, Form, Button as BootstrapButton, Button} from 'react-bootstrap';
import { Typography } from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import {AppDispatch} from "store/index";
import {selectProfitData, selectProfitError, selectProfitStatus} from "store/selectors/profit";
import {selectAuthData} from "store/selectors/auth";
import {ACCESS_TOKEN, RouteLocal} from "constants/common";
import useDealNotifications from '../../hooks/useDealNotifications';
import LoadingScreen from "../../components/Loader/Loader"
import {toast} from "react-toastify";
import {Navigate, useNavigate} from "react-router-dom";
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: none; /* Optional max width */
  padding: 0 20px; /* Add some padding for breathing space */
  box-sizing: border-box; /* Include padding in the element's total width and height */
`;

const Title = styled(Typography)`
  font-size: 3rem;
  font-weight: 900;
  text-align: center;
  margin-bottom: 40px;
  font-family: 'Roboto', sans-serif;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    font-size: 2rem;
    text-align: center;
  }
`;
const SectionTitle = styled.h2`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 25px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
`;



// Styled container for the settings section
const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 0.3s ease;
  background: linear-gradient(45deg, #ff6f61, #de1d23);
  padding: 1rem;
  border-radius: 8px;
`;

// Floating button for opening notification drawer
const FloatingButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #de1d23;
  color: white;
  border-radius: 50%;
  padding: 10px;
  font-size: 24px;
  cursor: pointer;
  border: none;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &:hover {
    background-color: #ff6f61;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
  }
`;

const NotificationDrawer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 350px; /* Increase width */
  height: 500px; /* Increase height */
  background-color: white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15); /* Larger shadow for more depth */
  z-index: 1000;
  padding: 20px;
  border-radius: 12px 12px 0 0; /* Rounded top corners */
  overflow-y: auto;
  
  
`;

const NotificationItem = styled.li`
  padding: 15px 20px; /* Increased padding for more comfortable reading */
  margin-bottom: 20px; /* More space between list items */
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #f0f0f0;
  }
`;
const CloseIcon = styled.div`
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 20px;
    cursor: pointer;
    color: #de1d23;
    &:hover {
        color: #ff6f61; /* Add a hover effect */
    }
`;
// Container for the table
const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto; /* Allow horizontal scrolling */
  margin-top: 40px;
  background: ${({ theme }) => theme.colors.card.background};
  border-radius: 15px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);

  /* Custom scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #de1d23; /* Match scrollbar color to the header */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1; /* Light background for scrollbar track */
  }
`;
// Table styling
const DealTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: 'Roboto', sans-serif;

  /* Header styling */
  thead th {
    background: #de1d23;
    color: white;
    padding: 12px 16px;
    text-align: left;
    cursor: pointer;
    position: relative;
    transition: background 0.3s;
    font-size: 0.95rem;

    &:hover {
      background: #ff6f61;
    }

    /* Sorting indicators */
    &.sorted-asc:after,
    &.sorted-desc:after {
      content: '';
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: white transparent transparent transparent;
      margin-left: 8px;
      display: inline-block;
    }

    &.sorted-asc:after {
      transform: translateY(-50%) rotate(180deg);
    }

    &.sorted-desc:after {
      transform: translateY(-50%);
    }

    /* Sortable indicator */
    &.sortable:before {
      content: '⇅';
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.2rem;
      color: #ffffffb3;
      transition: color 0.3s;
      font-weight: bold;
      padding-left: 10px;
    }
  }

  /* Body cell styling */
  tbody td {
    padding: 12px 16px;
    border-bottom: 1px solid #ecf0f1;
    font-size: 0.9rem;
    text-align: left; /* Ensures all text is left-aligned for consistency */
  }

  /* General media query to handle responsiveness */
  @media (max-width: 768px) {
    thead th,
    tbody td {
      font-size: 0.9rem;
    }
  }

  /* Mobile view adjustments */
  @media (max-width: 480px) {
    thead th,
    tbody td {
      font-size: 0.85rem;
      padding: 10px 12px;
    }

    tbody tr {
      border-radius: 8px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    }
  }
`;


const DealRow = styled.tr`
  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, transform 0.2s ease;
  }

  &.added {
    animation: rowAdded 0.5s ease-in-out;
  }

  @keyframes rowAdded {
    from {
      background-color: #e0ffe0;
      transform: scale(1.02);
    }
    to {
      background-color: transparent;
      transform: scale(1);
    }
  }
`;


const ExchangeSelection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  margin-bottom: 50px;
`;
const ExchangeCheckbox = styled(Form.Check)`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  label {
    margin-left: 10px;
    font-size: 1.3rem;
    font-family: 'Roboto', sans-serif;
    transition: color 0.3s ease;
  }

  input {
    width: 24px;
    height: 24px;
    accent-color: #ff6f61;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  &:hover label {
    color: #ff6f61;
  }
`;
const FilterSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 40px;

  input {
    flex: 1;
    margin: 10px;
    border-radius: 12px;
    padding: 12px;
    border: 1px solid #bdc3c7;
    font-size: 1rem;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    font-family: 'Roboto', sans-serif;

    &:focus {
      box-shadow: 0 0 8px rgba(255, 111, 97, 0.5);
      transform: scale(1.02);
      border-color: #ff6f61;
    }
  }
`;

const SubTitle = styled.h3`
  font-size: 1.8rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text.primary};
  text-align: left;
  margin-bottom: 20px;
  margin-top: 40px;
  font-family: 'Roboto', sans-serif;

  &::before {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    background: linear-gradient(45deg, #ff6f61, #de1d23);
    margin-bottom: 8px;
  }
`;

// Стилизованный компонент для списка бирж
const ExchangesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  padding: 15px;
  background: linear-gradient(45deg, #ff6f61, #de1d23);
  border-radius: 8px;
  color: white;
`;

// Компонент для выбора бирж
const MultiSelect = styled.select`
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background: white;
  color: black;
  width: 100%;
`;

// Кнопка для сохранения настроек
const SaveButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: linear-gradient(45deg, #ff6f61, #de1d23);
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: linear-gradient(45deg, #de1d23, #ff6f61);
  }
`;

// Контейнер для управления звуком
const SoundControl = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: linear-gradient(45deg, #ff6f61, #de1d23);
  border-radius: 8px;
  color: white;
`;
interface ProfitRange {
    askPrice: string;
    bidPrice: string;
    tradeAmount: number;
    profit: string;
    profitPercentage: string;
}

interface Range {
    totalAskCost: string;
    totalBidAmount: string;
    totalProfit: string;
    clearProf: string;
    profFee: string;
    totalFeeAmount: string;
    totalProfitAfterFee: string;
    totalProfitPercentage: string;
    range: string;
}

interface Comision {
    withdrawFee: string;
    network: string;
    depositEnable: boolean;
    withdrawEnable: boolean;
}

interface Deal {
    symbol: string;
    pl1name: string;
    pl1pricebuy: string;
    pl2pricesell: string;
    pl2name: string;
    active: boolean;
    spread: number;
    range: string; // JSON string
    comision: string; // JSON string
    linkOnPl1: string;
    linkOnPl2: string;
}

type SortConfig = {
    key: keyof Deal;
    direction: 'ascending' | 'descending';
} | null;

type FilterConfig = {
    minVolume: string;
    maxVolume: string;
};

const ArbitrageCoins: React.FC = () => {
    const dealsRef = useRef<Deal[]>([]);
    const navigate = useNavigate();
    const [filterConfig, setFilterConfig] = useState<FilterConfig>({
        minVolume: '',
        maxVolume: '',
    });
    const [history, setHistory] = useState<string[]>([]);
    const [selectedBuyExchanges, setSelectedBuyExchanges] = useState<string[]>([]);
    const [selectedSellExchanges, setSelectedSellExchanges] = useState<string[]>([]);
    const [profitFilter, setProfitFilter] = useState<number | null>(null);
    const [spreadFilter, setSpreadFilter] = useState<number | null>(null);
    const [sortConfig, setSortConfig] = useState<{ key: string | null, direction: 'ascending' | 'descending' }>({ key: null, direction: 'descending' });
    const [isSoundEnabled, setIsSoundEnabled] = useState<boolean>(true);
    const [showSettings, setShowSettings] = useState<boolean>(false);
    const [isSettingsSaved, setIsSettingsSaved] = useState<boolean>(false);
    const [previousDeals, setPreviousDeals] = useState<any[]>([]);
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    const [isFirstSave, setIsFirstSave] = useState<boolean>(true); // Новое состояние для отслеживания первого сохранения
    const [minRange, setMinRange] = useState<number | null>(null);
    const [maxRange, setMaxRange] = useState<number | null>(null);

    const { user } = useSelector(selectAuthData);
    const [data, setData] = useState<any>(null);
    const email = user?.email;
    const drawerRef = useRef<HTMLDivElement>(null);
    // Загружаем настройки из localStorage только если они есть
    useEffect(() => {
        const savedSettings = JSON.parse(localStorage.getItem('notificationSettings') || '{}');

        if (Object.keys(savedSettings).length > 0) {
            setProfitFilter(savedSettings.profitFilter);
            setSpreadFilter(savedSettings.spreadFilter);
            setSelectedBuyExchanges(savedSettings.selectedBuyExchanges);
            setSelectedSellExchanges(savedSettings.selectedSellExchanges);
            setIsSoundEnabled(savedSettings.isSoundEnabled);
            setShowSettings(!!savedSettings.profitFilter || !!savedSettings.spreadFilter);
            setIsSettingsSaved(true);  // Обозначаем, что настройки были сохранены
        }
    }, []);


    const handleSort = useCallback((key: string) => {
        const newDirection = sortConfig.key === key && sortConfig.direction === 'ascending'
            ? 'descending'
            : 'ascending';

        setSortConfig({
            key,
            direction: newDirection,
        });
    }, [sortConfig]);

    const handleSoundToggle = () => {
        setIsSoundEnabled(prev => !prev);
        localStorage.setItem('isSoundEnabled', JSON.stringify(!isSoundEnabled));
    };
    // Function to toggle the notification drawer
    const toggleNotificationDrawer = () => {
        setDrawerOpen(!isDrawerOpen);
    };
    const handleClickOutside = (event: MouseEvent) => {
        if (drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
            setDrawerOpen(false);
        }
    };

    const handleSaveSettings = useCallback(() => {
        // Очищаем previousDeals перед сохранением настроек
        setPreviousDeals([]);

        // Инициализируем previousDeals с текущими данными
        if (data?.data) {
            setPreviousDeals(data.data);
        }

        // Сохраняем настройки
        const settings = {
            profitFilter,
            spreadFilter,
            selectedBuyExchanges,
            selectedSellExchanges,
            isSoundEnabled,
        };
        localStorage.setItem('notificationSettings', JSON.stringify(settings));
        setIsSettingsSaved(true);
        setIsFirstSave(false);
        toast.success('Settings saved!');
    }, [profitFilter, spreadFilter, selectedBuyExchanges, selectedSellExchanges, isSoundEnabled]);

    const handleToggleSettings = () => {
        setShowSettings(prev => !prev);
    };



    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;

        const fetchData = async () => {
            const token = localStorage.getItem(ACCESS_TOKEN);
            if (!token) {
                toast('Token not found', { autoClose: 10_000 });
            }
            const response = await fetch(`${RouteLocal}/profit?email=${encodeURIComponent(email)}`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `${token}`
                }
            });
            if (!response.ok) {
                toast(`Network response was not ok: ${response.statusText}`, { autoClose: 10_000 });
                if (response.status === 401) {
                    return navigate('/');
                }
            }
            const resDate = await response.json();
            setData(resDate);
        };

        interval = setInterval(fetchData, 15000);

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, []);

    useEffect(() => {
        if (isDrawerOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isDrawerOpen]);

    useEffect(() => {
        if (!data || !isSettingsSaved) return;

        // Определяем новые сделки
        const newDeals = data.data.filter((deal: any) => {
            return !previousDeals.some((prevDeal: any) => prevDeal.symbol === deal.symbol);
        });


        if (newDeals.length > 0) {
            const notifications = useDealNotifications(
                newDeals,
                profitFilter,
                spreadFilter,
                selectedBuyExchanges,
                selectedSellExchanges,
                isSoundEnabled,
                isFirstSave, // Передаем флаг в useDealNotifications
                minRange,
                maxRange,  // Новые фильтры по диапазону
            );

            if (notifications.length > 0) {
                setHistory(prev => {
                    const updatedHistory = [...prev, ...notifications];
                    localStorage.setItem('notificationHistory', JSON.stringify(updatedHistory));
                    return updatedHistory;
                });

                setPreviousDeals(prev => [...prev, ...newDeals]);  // Обновляем только с новыми сделками
            }
        }
    }, [data, isSettingsSaved, profitFilter, spreadFilter, selectedBuyExchanges, selectedSellExchanges, isSoundEnabled]);
    const handleBuyExchangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;

        if (checked) {
            setSelectedBuyExchanges(prev => {
                const newExchanges = [...prev, value.trim().toLowerCase()];
                console.log("Updated Buy Exchanges:", newExchanges); // Логируем обновленные значения
                return newExchanges;
            });
        } else {
            setSelectedBuyExchanges(prev => {
                const updatedExchanges = prev.filter(exchange => exchange !== value.trim().toLowerCase());
                console.log("Updated Buy Exchanges:", updatedExchanges); // Логируем обновленные значения
                return updatedExchanges;
            });
        }
    };

    const handleSellExchangeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value, checked } = e.target;

        if (checked) {
            setSelectedSellExchanges(prev => {
                const newExchanges = [...prev, value.trim().toLowerCase()];

                return newExchanges;
            });
        } else {
            setSelectedSellExchanges(prev => {
                const updatedExchanges = prev.filter(exchange => exchange !== value.trim().toLowerCase());

                return updatedExchanges;
            });
        }
    };




    const finalFilteredDeals = useMemo(() => {
        if (!data || !Array.isArray(data.data)) return [];

        // Фильтруем сделки
        const filteredDeals = data.data.filter((deal: Deal) => {
            const dealBuyExchange = deal.pl1name.toLowerCase().trim();
            const dealSellExchange = deal.pl2name.toLowerCase().trim();
            const matchesBuyExchange = selectedBuyExchanges?.length === 0 || selectedBuyExchanges?.includes(dealBuyExchange);
            const matchesSellExchange = selectedSellExchanges?.length === 0 || selectedSellExchanges?.includes(dealSellExchange);
            // console.log(`dealBuyExchange: ${dealBuyExchange}, selectedBuyExchanges: ${selectedBuyExchanges}`);
            // console.log(`dealSellExchange: ${dealSellExchange}, selectedSellExchanges: ${selectedSellExchanges}`);
            //
            // console.log(`matchesBuyExchange: ${matchesBuyExchange}`);
            // console.log(`matchesSellExchange: ${matchesSellExchange}`);

            if (!matchesBuyExchange || !matchesSellExchange) return false;

            // Парсим и проверяем диапазон
            let range = {} as any;
            try {
                range = typeof deal.range === 'string' ? JSON.parse(deal.range) : deal.range || {};
            } catch {
                return false;
            }

            const clearProfit = parseFloat(range.clearProf?.replace(/[^0-9.-]+/g, "") || "0");
            const totalProfitPercentage = parseFloat(range.totalProfitPercentage?.replace(/[^0-9.-]+/g, "") || "0");
            const totalAskCost = parseFloat(range.totalAskCost?.replace(/[^0-9.-]+/g, "") || "0");
            const totalBidAmount = parseFloat(range.totalBidAmount?.replace(/[^0-9.-]+/g, "") || "0");

            const profitCondition = profitFilter ? clearProfit >= profitFilter : true;
            const spreadCondition = spreadFilter ? totalProfitPercentage >= spreadFilter : true;

            // Добавляем фильтр по диапазону
            const rangeCondition = (minRange ? totalAskCost >= minRange : true) && (maxRange ? totalBidAmount <= maxRange : true);

            return profitCondition &&
                spreadCondition &&
                rangeCondition
                &&
                matchesBuyExchange &&
                matchesSellExchange;
        });


        // Сортируем сделки
        return filteredDeals.sort((a: any, b: any) => {
            if (!sortConfig.key) return 0;

            const getNestedValue = (obj: any, key: string) => key.split('.').reduce((value, keyPart) => value?.[keyPart], obj);
            const cleanValue = (value: any) => {
                if (typeof value === 'string') {
                    const cleaned = value.replace(/[^0-9.-]+/g, '');
                    return isNaN(Number(cleaned)) ? 0 : Number(cleaned);
                }
                return isNaN(Number(value)) ? 0 : Number(value);
            };

            const aValue = cleanValue(getNestedValue(a, sortConfig.key));
            const bValue = cleanValue(getNestedValue(b, sortConfig.key));

            if (aValue < bValue) return sortConfig.direction === 'ascending' ? -1 : 1;
            if (aValue > bValue) return sortConfig.direction === 'ascending' ? 1 : -1;
            return 0;
        });
    }, [data, selectedBuyExchanges, selectedSellExchanges, profitFilter, spreadFilter, sortConfig]);

    function formatNumber(value: number): string {
        return value.toLocaleString(undefined, { minimumFractionDigits: 8, maximumFractionDigits: 8 });
    }

    if (!data) {
        return   <LoadingScreen text="Please wait..." size={48} color="#fff" />;
    }

    return (
        <Container>
            <Title variant="h2">Inter-Exchange Scanner</Title>
            <SectionTitle>Select Exchanges</SectionTitle>
            <SubTitle>Buying:</SubTitle>
            <ExchangeSelection>
                <ExchangeCheckbox
                    label="Binance"
                    type="checkbox"
                    value="Binance"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="ByBit"
                    type="checkbox"
                    value="ByBit"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="KuCoin"
                    type="checkbox"
                    value="KuCoin"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="Mexc"
                    type="checkbox"
                    value="Mexc"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="Bitget"
                    type="checkbox"
                    value="Bitget"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="Bitmart"
                    type="checkbox"
                    value="Bitmart"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="Poloniex"
                    type="checkbox"
                    value="Poloniex"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="Xt"
                    type="checkbox"
                    value="Xt"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="Gate"
                    type="checkbox"
                    value="Gate"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="OKX"
                    type="checkbox"
                    value="okx"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="Coinex"
                    type="checkbox"
                    value="Coinex"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="Huobi"
                    type="checkbox"
                    value="Huobi"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="BingX"
                    type="checkbox"
                    value="BingX"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="CoinW"
                    type="checkbox"
                    value="CoinW"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="Digifinex"
                    type="checkbox"
                    value="Digifinex"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="Probit"
                    type="checkbox"
                    value="Probit"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="Tapbit"
                    type="checkbox"
                    value="Tapbit"
                    onChange={handleBuyExchangeChange}
                />
                <ExchangeCheckbox
                    label="hitBTC"
                    type="checkbox"
                    value="hitBTC"
                    onChange={handleBuyExchangeChange}
                />

                {/* Add more exchanges as needed */}

            </ExchangeSelection>
            <SubTitle>Selling:</SubTitle>
            <ExchangeSelection>
                <ExchangeCheckbox
                    label="Binance"
                    type="checkbox"
                    value="Binance"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="ByBit"
                    type="checkbox"
                    value="ByBit"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="KuCoin"
                    type="checkbox"
                    value="KuCoin"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="Mexc"
                    type="checkbox"
                    value="Mexc"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="Bitget"
                    type="checkbox"
                    value="Bitget"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="Bitmart"
                    type="checkbox"
                    value="Bitmart"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="Poloniex"
                    type="checkbox"
                    value="Poloniex"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="Xt"
                    type="checkbox"
                    value="Xt"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="Gate"
                    type="checkbox"
                    value="Gate"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="OKX"
                    type="checkbox"
                    value="okx"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="Coinex"
                    type="checkbox"
                    value="Coinex"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="Huobi"
                    type="checkbox"
                    value="Huobi"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="BingX"
                    type="checkbox"
                    value="BingX"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="CoinW"
                    type="checkbox"
                    value="CoinW"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="Digifinex"
                    type="checkbox"
                    value="Digifinex"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="Probit"
                    type="checkbox"
                    value="Probit"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="Tapbit"
                    type="checkbox"
                    value="Tapbit"
                    onChange={handleSellExchangeChange}
                />
                <ExchangeCheckbox
                    label="hitBTC"
                    type="checkbox"
                    value="hitBTC"
                    onChange={handleSellExchangeChange}
                />
                {/* Добавьте остальные биржи по аналогии */}
            </ExchangeSelection>
            <SectionTitle>Set Parameters</SectionTitle>
            <FilterSection>
                <Form.Control
                    type="number"
                    step="0.01" // Allows for decimal input
                    placeholder="Profit $"
                    value={profitFilter || ''}
                    onChange={(e) => {
                        let value = e.target.value ? parseFloat(e.target.value) : null;
                        if (value !== null && value < 0) {
                            value = 0; // Set to 0 if the input is negative
                        }
                        setProfitFilter(value);
                    }}
                />
                <Form.Control
                    type="number"
                    step="0.01" // Allows for decimal input
                    placeholder="Spread %"
                    value={spreadFilter || ''}
                    onChange={(e) => {
                        let value = e.target.value ? parseFloat(e.target.value) : null;
                        if (value !== null && value < 0) {
                            value = 0; // Set to 0 if the input is negative
                        }
                        setSpreadFilter(value);
                    }}
                />
            </FilterSection>
            <SectionTitle>Set Range Filter</SectionTitle>
            <FilterSection>
                <Form.Control
                    type="number"
                    step="0.01"
                    placeholder="Min Ask Cost"
                    value={minRange || ''}
                    onChange={(e) => {
                        let value = e.target.value ? parseFloat(e.target.value) : null;
                        if (value !== null && value < 0) value = 0;
                        setMinRange(value);
                    }}
                />
                <Form.Control
                    type="number"
                    step="0.01"
                    placeholder="Max Bid Amount"
                    value={maxRange || ''}
                    onChange={(e) => {
                        let value = e.target.value ? parseFloat(e.target.value) : null;
                        if (value !== null && value < 0) value = 0;
                        setMaxRange(value);
                    }}
                />
            </FilterSection>

            <SectionTitle>Notification Settings</SectionTitle>
            <Form.Check
                type="switch"
                id="notification-toggle"
                label="Enable Notifications"
                checked={showSettings}
                onChange={handleToggleSettings}
            />

            {showSettings && (
                <SettingsContainer>
                    <SaveButton onClick={handleSaveSettings}>Save Settings</SaveButton>
                    <SoundControl>
                        <Form.Check
                            type="switch"
                            id="sound-toggle"
                            label="Sound"
                            checked={isSoundEnabled}
                            onChange={handleSoundToggle}
                        />
                    </SoundControl>
                </SettingsContainer>
            )}

            <FloatingButton onClick={toggleNotificationDrawer}>🔔</FloatingButton>

            {isDrawerOpen && (
                <NotificationDrawer ref={drawerRef}>
                    <CloseIcon onClick={() => setDrawerOpen(false)}>✖</CloseIcon>
                    <SectionTitle>History of Notifications</SectionTitle>
                    <ul>
                        {history.map((note, index) => (
                            <NotificationItem key={index}>{note}</NotificationItem>
                        ))}
                    </ul>
                </NotificationDrawer>
            )}


            <TableContainer>
                <DealTable>
                    <thead>
                    <tr>
                        <th>Currency</th>
                        <th>Exchanges</th>
                        <th>Prices</th>
                        <th>Range</th>
                        <th className="sortable" onClick={() => { handleSort('clearProfit'); }}>Clear Profit</th>
                        <th >Total Fee Amount</th>
                        <th className="sortable" onClick={() => { handleSort('totalProfitPercentage'); }}>Total Profit Percentage</th>
                        <th>Commission</th>
                    </tr>
                    </thead>
                    <tbody>
                    {finalFilteredDeals.map((deal: any, index: any) => {
                        let range: Range = {} as Range;
                        let comision: Comision[] = [];
                        const symbol = deal.symbol;
                        const extractedSymbol = symbol.split('_')[0];
                        try {
                            range = JSON.parse(deal.range);
                        } catch (e) {
                            console.error("Failed to parse range:", e);
                        }

                        try {
                            comision = JSON.parse(deal.comision);
                        } catch (e) {
                            console.error("Failed to parse comision:", e);
                        }



                        return (
                            <DealRow key={deal.id || index} className={deal.isNew ? 'added' : ''}>
                                <td>{extractedSymbol}</td>
                                <td> <a href={deal.linkOnPl1} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px' }}>
                                    {deal.pl1name}
                                </a>       <a href={deal.linkOnPl2} target="_blank" rel="noopener noreferrer">
                                    {deal.pl2name}
                                </a> </td>
                                <td>{deal.pl1pricebuy} / {deal.pl2pricesell}</td>
                                <td>
                                    {range ? (
                                        (() => {
                                            try {
                                                return (
                                                    <div>
                                                        <p>{range.range}</p>
                                                    </div>
                                                );
                                            } catch (e) {
                                                return <p>Error parsing range data</p>;
                                            }
                                        })()
                                    ) : (
                                        'N/A'
                                    )}
                                </td>
                                <td>
                                    {range ? (
                                        (() => {
                                            try {
                                                return (
                                                    <div>
                                                        <p>{range.clearProf}</p>
                                                    </div>
                                                );
                                            } catch (e) {
                                                return <p>Error parsing range data</p>;
                                            }
                                        })()
                                    ) : (
                                        'N/A'
                                    )}
                                </td>
                                <td>
                                    {range ? (
                                        (() => {
                                            try {
                                                return (
                                                    <div>
                                                        <p>{range.totalFeeAmount}</p>
                                                    </div>
                                                );
                                            } catch (e) {
                                                return <p>Error parsing range data</p>;
                                            }
                                        })()
                                    ) : (
                                        'N/A'
                                    )}
                                </td>
                                <td>
                                    {range ? (
                                        (() => {
                                            try {
                                                return (
                                                    <div>
                                                        <p>{range.totalProfitPercentage}</p>
                                                    </div>
                                                );
                                            } catch (e) {
                                                return <p>Error parsing range data</p>;
                                            }
                                        })()
                                    ) : (
                                        'N/A'
                                    )}
                                </td>
                                <td>
                                    {deal.profitFee}
                                    {comision.length > 0 ? (
                                        comision.map((item, i) => (
                                            <div key={i}>
                                                <p>Withdraw Fee: {item.withdrawFee}</p>
                                                <p>Network: {item.network}</p>
                                            </div>
                                        ))
                                    ) : (
                                        'N/A'
                                    )}
                                </td>
                            </DealRow>
                        );
                    })}
                    </tbody>
                </DealTable>
            </TableContainer>
        </Container>
    );
};


export default ArbitrageCoins;
