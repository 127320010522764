import { FC, memo } from 'react';

import { TypographyComponent, TypographyType } from 'enums/ui';

import { StyledTypography } from './Typography.styles';
import { TypographyProps } from './Typography.types';

export const Typography: FC<TypographyProps> = memo(
  ({
    children,
    size,
    component = TypographyComponent.P,
    weight,
    dataTestId,
    type = TypographyType.Default,
    ...props
  }) => {
    const as =
      component === TypographyComponent.H7 || component === TypographyComponent.H8
        ? TypographyComponent.P
        : component;

    return (
      <StyledTypography
        as={as}
        component={component}
        data-testid={dataTestId}
        size={size}
        type={type}
        weight={weight}
        {...props}
      >
        {children}
      </StyledTypography>
    );
  },
);
