import { DefaultTheme } from 'styled-components';

import { TypographyComponent, TypographySize, TypographyType, TypographyWeight } from 'enums/ui';

export function resolveFontSize(
  component?: TypographyComponent,
  size: TypographySize = TypographySize.Medium,
) {
  switch (component) {
    case TypographyComponent.H1:
      return '72px';

    case TypographyComponent.H2:
      return '60px';

    case TypographyComponent.H3:
      return '40px';

    case TypographyComponent.H4:
      return '26px';

    case TypographyComponent.H5:
      return '20px';

    case TypographyComponent.H6:
      return '16px';

    case TypographyComponent.H7:
      return '14px';

    case TypographyComponent.H8:
      return '12px';

    default:
      return size === TypographySize.Small ? '12px' : '16px';
  }
}

export function resolveLineHeight(
  component?: TypographyComponent,
  size: TypographySize = TypographySize.Medium,
) {
  switch (component) {
    case TypographyComponent.H1:
      return '72px';

    case TypographyComponent.H2:
      return '68px';

    case TypographyComponent.H3:
      return '48px';

    case TypographyComponent.H4:
      return '34px';

    case TypographyComponent.H5:
      return '28px';

    case TypographyComponent.H6:
      return '24px';

    case TypographyComponent.H7:
      return '20px';

    case TypographyComponent.H8:
      return '16px';

    default:
      return size === TypographySize.Small ? '20px' : '24px';
  }
}

export function resolveWeight(
  component?: TypographyComponent,
  weight: TypographyWeight = TypographyWeight.Light,
) {
  switch (component) {
    case TypographyComponent.H1:
      return TypographyWeight.Bold;

    case TypographyComponent.H2:
    case TypographyComponent.H3:
    case TypographyComponent.H4:
    case TypographyComponent.H5:
      return TypographyWeight.SemiBold;

    default:
      return weight;
  }
}

export function getColorByVariant(
  type: TypographyType = TypographyType.Default,
  theme: DefaultTheme,
) {
  switch (type) {
    case TypographyType.Default:
      return theme.colors.text.primary;

    case TypographyType.Error:
      return theme.colors.status.error;

    case TypographyType.Success:
      return theme.colors.status.success;

    case TypographyType.Secondary:
      return theme.colors.text.secondary;

    case TypographyType.CardLabel:
      return theme.colors.card.icon;
  }
}
