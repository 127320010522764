import { NavLink } from 'react-router-dom';
import { darken, lighten } from 'polished';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

import { MediaQueries } from 'enums/ui';

import { Typography } from 'components/Typography';

export const NavigationContainer = styled.nav<{ $isOpenSidebar: boolean }>`
  position: sticky;
  top: 0;
  // overlaps toasts
  z-index: 11001;

  display: flex;
  flex-direction: column;

  height: min-content;
  width: 300px;
  min-width: 300px;
  margin-right: 50px;

  @media (max-width: ${MediaQueries.Tablet}) {
    position: absolute;
    left: 0;
    // related to bug 19439, helps to avoid hiding header when focusout from any field on IOS
    top: -1000px;
    height: 100vh;

    background: ${({ theme }) => theme.colors.card.background};

    transform: translateX(-300px);
    transition: transform 0.5s ease, top 0s ease 0.5s;

    ${ifProp(
      '$isOpenSidebar',
      css`
        top: 0;
        transform: translateX(0);
        transition: transform 0.5s ease;
      `,
    )};
  }
`;

export const NavigationLink = styled(NavLink)<{ $isDarkMode: boolean }>`
  display: flex;
  align-items: center;

  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 3px;

  cursor: pointer;

  &&.active {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  &:hover {
    background-color: ${({ theme, $isDarkMode }) =>
      $isDarkMode
        ? lighten(0.05, theme.colors.card.background)
        : darken(0.05, theme.colors.card.background)};
  }

  @media (max-width: ${MediaQueries.Tablet}) {
    margin: 0 15px 10px 15px;
  }
`;

export const NavigationLinkText = styled(Typography)<{ $isActive: boolean }>`
  margin-left: 10px;

  ${ifProp(
    '$isActive',
    css`
      color: ${({ theme }) => theme.colors.white};
    `,
  )}
`;

export const Overlay = styled.div<{ $isOpenSidebar: boolean }>`
  // overlaps toasts
  z-index: 11000;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  
  opacity: 0;
  transition: opacity 0.5s ease, height 0s ease 0.5s, width 0s ease 0.5s;
  background: ${({ theme }) => theme.colors.black};
  
  ${ifProp(
    '$isOpenSidebar',
    css`
      @media (max-width: ${MediaQueries.Tablet}) {
        opacity: 0.5;

        transition: opacity 0.5s ease;

        width: 100vw;
        height: 100vh;
      }
    `,
  )}
}
  
 
`;
