export enum TypographyComponent {
  P = 'p',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  H7 = 'h7',
  H8 = 'h8',
  Error = 'p',
}

export enum TypographyType {
  Default = 'default',
  Error = 'error',
  Success = 'success',
  Secondary = 'secondary',
  CardLabel = 'cardLabel',
}

export enum TypographySize {
  Medium = 'medium',
  Small = 'small',
}

export enum TypographyWeight {
  Bold = '700',
  SemiBold = '600',
  Regular = '400',
  Light = '300',
}

export enum MediaQueries {
  Desktop = '1500px',
  Tablet = '1150px',
  MobileLandscape = '766px',
  Mobile = '501px',
}

export enum ButtonVariant {
  Primary = 'primary',
  Transparent = 'transparent',
}

export enum ScreenType {
  Desktop = 'Desktop',
  Tablet = 'Tablet',
  MobileLandscape = 'MobileLandscape',
  Mobile = 'Mobile',
}
