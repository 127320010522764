import styled from 'styled-components';
import { darkTheme } from '../../styles/themes/dark';
import { lightTheme } from '../../styles/themes/light';
import { MediaQueries } from 'enums/ui';
import {Card} from "react-bootstrap";
import {fadeIn, pulse} from "./components/PriceInfo";
import {Typography} from "@mui/material";
import {keyframes} from "@emotion/react";

export const DashboardContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const FirstBlockContainer = styled.div`
  display: flex;
  @media (max-width: ${MediaQueries.Desktop}) {
    flex-direction: column;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const HeadText = styled.h1`
  font-size: 46px;
  font-weight: 700;
  line-height: 67.2px;
  text-align: center;
  margin-bottom: 20px;
`;

export const HeadTextP = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
`;


export const BlockPrice = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  margin: 20px 0;
  color: ${({ theme }) => theme.colors.text.primary};
`;


export const PriceP = styled.p`
  font-size: 56px;
  font-weight: 700;
  line-height: 67.2px;
  text-align: left;
  border-bottom: 2px solid grey;
  margin-bottom: 20px;
`


export const SlideContainer = styled.div`
  color: ${({ theme }) => theme.colors.text.primary};
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  padding-top: 10px;
`;

export const Slide = styled.div<{ animation: any }>`
  position: absolute;
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: ${props => props.animation} 1s ease-in-out;
  text-align: center;
  background: ${({ theme }) => theme.colors.card.background};
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  border-radius: 10px; /* Rounded corners */
`;

export const PricingBlock = styled(Card)`
  border-radius: 12px;
  background: ${({ theme }) => theme.colors.card.background};
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  padding: 30px;
  text-align: center;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  max-width: 300px;
  min-width: 200px;
  flex: 1;
  animation: ${fadeIn} 1s ease-in-out; /* Применяется только при рендере */
  &:hover {
    transform: translateY(-5px); /* Меньше движение на hover */
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.25);
  }
`;
export const BlockTitle = styled(Typography)`
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 25px;
  text-align: left;
`;
export const Feature = styled.li`
  margin: 10px 0;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colors.text.primary};
  padding-left: 25px;
  position: relative;

  &::before {
    content: '✔';
    color: ${({ theme }) => theme.colors.primary};
    position: absolute;
    left: 0;
    padding-right: 10px;
    animation: ${fadeIn} 1s ease-in-out; /* Анимация только при первом рендере */
  }

  &:hover::before {
    animation: ${pulse} 1s infinite;
  }
`;

export const PricingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 30px;
  padding: 40px;
`;

export interface AnswerProps {
    isExpanded: boolean;
}

export const Question = styled(Typography)`
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const Answer = styled.div<AnswerProps>`
  font-size: 1rem;
  padding: ${({ isExpanded }) => (isExpanded ? '15px 20px' : '0 20px')};
  border-top: 1px solid #ddd;
  max-height: ${({ isExpanded }) => (isExpanded ? '200px' : '0')}; /* Задайте разумное значение для max-height */
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
  color: ${({ theme }) => theme.colors.text.primary};
`;

export const FAQCard = styled(Card)`
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: ${({ theme }) => theme.colors.card.background};
  &:hover {
    transform: scale(1.02);
  }
`;

export const ToggleIcon = styled.span`
  font-size: 1.5rem;
  transition: transform 0.3s ease;

  &.collapsed {
    transform: rotate(0deg);
  }

  &.expanded {
    transform: rotate(180deg);
  }
`;

export const FAQSection = styled.section`
  padding: 50px 0;
  animation: ${fadeIn} 2s ease-in-out;
  margin-bottom: 50px;
`;

export const FAQTitle = styled(Typography)`
  font-size: 7rem;  /* Очень крупный текст */
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
`;