import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { QueryFulfilledRejectionReason } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes';
import { PromiseWithKnownReason } from '@reduxjs/toolkit/src/query/core/buildMiddleware/types';

import {
  ACCESS_TOKEN,
  ACCESS_TOKEN_EXPIRATION_DATE,
  REALTOR_ID,
  REFRESH_TOKEN,
  REFRESH_TOKEN_EXPIRATION_DATE,
} from 'constants/common';
import { AuthType } from 'enums/auth';
import { getTimeLeft } from 'utils/date';
import { formatPhoneForRequest } from 'utils/formatting';

import { TokenResponse } from 'services/authAPI/types';
import { SignInDetails } from 'types/auth';

export const getCreateRequestPayload = ({
  isEmail,
  companyId,
  email,
  redirectUrl,
  phoneNumber,
}: SignInDetails) =>
  isEmail
    ? {
        type: AuthType.ViaEmail,
        companyId,
        email: email || '',
        redirectUrl: window.origin + (redirectUrl || ''),
      }
    : {
        type: AuthType.ViaPhone,
        companyId,
        phoneNumber: formatPhoneForRequest(phoneNumber || ''),
      };

export const saveToken = (accessToken: any) => {
    if (accessToken ) {
    localStorage.setItem(ACCESS_TOKEN, accessToken);
  }
};

export const getExpirationCodeMinutes = (expirationDate: string) =>
  getTimeLeft({
    date: expirationDate,
    time: 'minutes',
    rounding: 'ceil',
    onlyPositiveValues: true,
  });
