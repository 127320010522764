import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Container, Row, Col, Button as BootstrapButton} from 'react-bootstrap';
import { Typography } from '@mui/material';
import InterExImage from '../../../assets/images/Screenshot 2024-08-19 at 17.58.44.png';
import {Link} from "react-router-dom";
import { darkTheme } from '../../../../src/styles/themes/dark';
import { lightTheme } from '../../../../src/styles/themes/light';
// Keyframes for animations
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;


const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Changed this to flex-start to align text at the top */
  padding: 50px;
  animation: ${fadeIn} 2s ease-in-out;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px;
  }
`;

const Title = styled(Typography)`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.2; /* Adjusted line height for closer lines */

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Further reduced font size */
    line-height: 1.2; /* Ensures that lines are closer together */
    margin-bottom: 20px;
    word-wrap: break-word; /* Prevents overflow */
    hyphens: auto; /* Allows for better word wrapping */
  }
`;

const Subtitle = styled(Typography)`
  font-size: 1.5rem;
  margin-top: 20px; /* Consistent spacing from the title */
  animation: ${fadeIn} 2s ease-in-out;

  @media (max-width: 768px) {
    font-size: 1.1rem; /* Slightly reduced font size */
    margin-top: 15px; /* Slightly adjusted spacing */
  }
`;

const ImageSection = styled.div`
  max-width: 45%; /* Reduced width to make more space for the text */
  margin-left: 40px;
  animation: ${fadeIn} 2s ease-in-out;

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
`;

const TextSection = styled.div`
  max-width: 50%;
  padding-right: 40px;
  animation: ${fadeIn} 2s ease-in-out;

  @media (max-width: 768px) {
    max-width: 100%;
    padding-right: 0;
    padding-left: 10px; /* Added padding on the left for better spacing */
    padding-right: 10px; /* Added padding on the right for symmetry */
    margin-bottom: 30px;
    margin-top: 20px; /* Increased margin at the top */
  }
`;

const ChoosePlanButton = styled(BootstrapButton)`
  background: linear-gradient(45deg, #ff6f61, #de1d23);
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  padding: 15px 30px;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background: linear-gradient(45deg, #de1d23, #ff6f61);
    transform: scale(1.05);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 10px 20px;
  }
`;

const HomePage = () => {
    return (
        <Container>
            <HeaderSection>
                <TextSection>
                    <Title variant="h2">Welcome to ArbitrageMirage!</Title>
                    <Subtitle variant="h5">
                        Our project is designed for those who want to effectively utilize inter-exchange arbitrage and funding opportunities. We offer unique software that automates these processes, ensuring maximum profit with minimal risks.
                    </Subtitle>
                    <Link to="/#pricing-section">
                        <ChoosePlanButton>Choose Your Plan</ChoosePlanButton>
                    </Link>
                </TextSection>
                <ImageSection>
                    <img src={InterExImage} alt="ArbitrageMirage" />
                </ImageSection>
            </HeaderSection>
        </Container>
    );
};
export default HomePage;
