import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

interface Deal {
    symbol: string;
    pl1name: string;
    pl1pricebuy: string;
    pl2pricesell: string;
    pl2name: string;
    active: boolean;
    spread: number;
    range: string; // JSON string
    comision: string; // JSON string
    linkOnPl1: string;
    linkOnPl2: string;
}

// Функция для создания сообщения с профитом и диапазоном
const createDealMessage = (deal: Deal) => {
    let range = {} as any;
    try {
        range = typeof deal.range === 'string' ? JSON.parse(deal.range) : deal.range || {};
    } catch {
        range = {};
    }

    // Извлечение значений профита и других данных
    const clearProfit = parseFloat(range.clearProf?.replace(/[^0-9.-]+/g, "") || "0");
    const totalProfitPercentage = parseFloat(range.totalProfitPercentage?.replace(/[^0-9.-]+/g, "") || "0");
    const totalAskCost = parseFloat(range.totalAskCost?.replace(/[^0-9.-]+/g, "") || "0");
    const totalBidAmount = parseFloat(range.totalBidAmount?.replace(/[^0-9.-]+/g, "") || "0");

    // Формирование сообщения
    const message = `New Trade Alert: ${deal.symbol}
Profit: $${clearProfit.toFixed(2)}
Total Profit Percentage: ${totalProfitPercentage.toFixed(2)}%
Price Range: $${totalAskCost.toFixed(2)} - $${totalBidAmount.toFixed(2)}`;

    return message;

};
const useDealNotifications = (
    newDeals: any[],
    profitFilter: number | null,
    spreadFilter: number | null,
    selectedBuyExchanges: string[],
    selectedSellExchanges: string[],
    isSoundEnabled: boolean,
    isFirstSave: boolean,  // Новое состояние
    minRange: number | null,  // Новые фильтры по диапазону
    maxRange: number | null,  // Новые фильтры по диапазону
) => {
    let notifications: any[] = [];

    // Фильтрация новых сделок
    const filteredDeals = newDeals.filter((deal: any) => {
        const dealBuyExchange = deal.pl1name.toLowerCase().trim();
        const dealSellExchange = deal.pl2name.toLowerCase().trim();
        const matchesBuyExchange = selectedBuyExchanges.length === 0 || selectedBuyExchanges.includes(dealBuyExchange);
        const matchesSellExchange = selectedSellExchanges.length === 0 || selectedSellExchanges.includes(dealSellExchange);

        if (!matchesBuyExchange || !matchesSellExchange) return false;

        let range = {} as any;
        try {
            if (typeof deal.range === 'string') {
                range = JSON.parse(deal.range);
            } else if (typeof deal.range === 'object' && deal.range !== null) {
                range = deal.range;
            } else {
                return false;
            }
        } catch {
            return false;
        }

        const clearProfit = parseFloat(range.clearProf?.replace(/[^0-9.-]+/g, "") || "0");
        const totalProfitPercentage = parseFloat(range.totalProfitPercentage?.replace(/[^0-9.-]+/g, "") || "0");
        const totalAskCost = parseFloat(range.totalAskCost?.replace(/[^0-9.-]+/g, "") || "0");
        const totalBidAmount = parseFloat(range.totalBidAmount?.replace(/[^0-9.-]+/g, "") || "0");

        const profitCondition = profitFilter ? clearProfit >= profitFilter : true;
        const spreadCondition = spreadFilter ? totalProfitPercentage >= spreadFilter : true;
        const rangeCondition = (minRange ? totalAskCost >= minRange : true) && (maxRange ? totalBidAmount <= maxRange : true);

        return profitCondition &&
            spreadCondition &&
            rangeCondition
            // &&
            // (selectedBuyExchanges.length === 0 || selectedBuyExchanges.includes(deal.pl1name.toLowerCase().trim())) &&
            // (selectedSellExchanges.length === 0 || selectedSellExchanges.includes(deal.pl2name.toLowerCase().trim()));

    });

    // Блокируем уведомления при первом сохранении
    if (isFirstSave && filteredDeals.length > 0) {
        return [];  // Не отправляем уведомления при первом сохранении
    }

    // Отправляем уведомления только для отфильтрованных сделок
    filteredDeals.forEach((deal: any) => {
        const message = createDealMessage(deal);
        if (Notification.permission === 'granted') {
            new Notification('New Deal Notification', { body: message });
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    new Notification('New Deal Notification', { body: message });
                }
            });
        }

        if ((document.visibilityState === 'visible' || document.visibilityState === 'hidden') && isSoundEnabled) {
            const audio = new Audio('/736267__universfield__new-notification-7.mp3');
            audio.play().catch(() => {
                toast.error('Failed to play notification sound. Make sure you have interacted with the page or allowed sound notifications.');
            });
        }


        notifications = [...notifications, message];
    });

    return notifications;
};


export default useDealNotifications;
