import { useSelector } from "react-redux";
import { selectAuthData } from "store/selectors/auth";
import { Navigate } from 'react-router-dom';

const AuthWrapper: ({children}: { children: any }) => (JSX.Element) = ({ children }) => {
    const { isAuthenticated } = useSelector(selectAuthData);

    if(isAuthenticated) {
        return (<>{children}</>)
    } else {
        return <Navigate to='/dashboard'/>
    }

};

export default AuthWrapper;