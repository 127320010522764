import { ScreenType } from 'enums/ui';

import { RootState } from 'store/index';

export const selectScreenType = (state: RootState) => state.ui.screenType;

export const selectIsDesktopView = (state: RootState) => {
  const screenType = selectScreenType(state);
  return screenType === ScreenType.Desktop;
};

export const selectIsMobileView = (state: RootState) => {
  const screenType = selectScreenType(state);
  return screenType === ScreenType.Mobile;
};

export const selectIsMobileLandscapeAndLower = (state: RootState) => {
  const screenType = selectScreenType(state);
  return screenType === ScreenType.Mobile || screenType === ScreenType.MobileLandscape;
};

export const selectIsTabletAndHigher = (state: RootState) => {
  const screenType = selectScreenType(state);
  return screenType === ScreenType.Desktop || screenType === ScreenType.Tablet;
};
